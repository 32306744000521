/** @format */

import React, { useEffect } from "react";
import "./style.css";
import { Button, Input, Modal, DatePicker } from "antd";
import { Row, Col } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faFilter,
  faSort,
} from "@fortawesome/free-solid-svg-icons";
// import { useParams } from "react-router-dom";
import { Menu, Dropdown, message, Space } from "antd";
import MerchantSettlementsTable from "../../components/MerchantSettlementsTable";
import { useDispatch } from "react-redux";

const { Search } = Input;
const { RangePicker } = DatePicker;

export default function MerchantSettlements({ visible = false }) {
  // const params = useParams();
  const [visiblePop, setVisiblePop] = React.useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    // dispatch(getMerchantSettlements(params.merchantId));
  }, [dispatch]);
  const [confirmLoading, setConfirmLoading] = React.useState(false);

  const showModal = () => {
    setVisiblePop(true);
  };

  const handleOk = () => {
    setConfirmLoading(true);
    setTimeout(() => {
      setVisiblePop(false);
      setConfirmLoading(false);
    }, 2000);
  };
  const handleCancel = () => {
    setVisiblePop(false);
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key='1'>Alphabetically (A-Z)</Menu.Item>
      <Menu.Item key='2'>Alphabetically (Z-A)</Menu.Item>
    </Menu>
  );
  const menuTwo = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key='1' onClick={showModal}>
        Date
      </Menu.Item>
      <Menu.Item key='2'>Trans.Status - Successe</Menu.Item>
      <Menu.Item key='3'>Trans.Status - Failed</Menu.Item>
      <Menu.Item key='4'>Payout.Status - Success</Menu.Item>
      <Menu.Item key='5'>Payout.Status - Failed</Menu.Item>
      <Menu.Item key='6'>Payout.Status - Pending</Menu.Item>
    </Menu>
  );
  const menuThree = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key='1'>Settle Transaction</Menu.Item>
    </Menu>
  );

  function handleMenuClick(e) {
    message.info("Click on menu item.");
  }
  if (!visible) return null;
  return (
    <div>
      <div className='large-header secondary pt-30'>
        BuyFood Africa - Settlements
      </div>
      <div className='primary-header'>
        Here are the transactions for primhex Agency
      </div>
      <Row
        gutter={16}
        style={{ marginTop: 20, justifyContent: "space-between" }}>
        <Col className='gutter-row' span={6}>
          <Search
            placeholder='input search text'
            enterButton
            color='green'
            style={{ backgroundColor: "#5322DB" }}
          />
        </Col>
        <Col className='gutter-row'>
          <Space wrap>
            <Dropdown overlay={menu}>
              <Button
                type='text'
                style={{ backgroundColor: "#7677AE", color: "white" }}
                className='text-white br-5'>
                Sort{" "}
                <FontAwesomeIcon icon={faSort} className='ml-10 text-white' />
              </Button>
            </Dropdown>
            <Dropdown overlay={menuTwo}>
              <Button
                type='text'
                style={{ backgroundColor: "#5322DB" }}
                className='text-white br-5'>
                Filter{" "}
                <FontAwesomeIcon icon={faFilter} className='ml-10 text-white' />
              </Button>
            </Dropdown>
            <Dropdown overlay={menuThree}>
              <Button
                type='text'
                style={{ backgroundColor: "#131573" }}
                className='text-white br-5'>
                Actions{" "}
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className='ml-10 text-white'
                />
              </Button>
            </Dropdown>
          </Space>
        </Col>
      </Row>
      <MerchantSettlementsTable />
      <Modal
        title='Filter By Date'
        visible={visiblePop}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}>
        <RangePicker onChange={(date, dateString) => {}} />
      </Modal>
    </div>
  );
}
