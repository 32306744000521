import React from "react";
import { Table, Tag } from "antd";
import { Link } from "react-router-dom";

const columns = [
  {
    title: "REF.ID",
    dataIndex: "ref",
    render: (text) => {
      return (
        <Link
          to={`/company-admin/merchants/${text.split(" ").join("-")}`}
          key={text}
        >
          {text}
        </Link>
      );
    },
  },
  {
    title: "DETAILS",
    dataIndex: "details",
  },
  {
    title: "CHANNEL",
    dataIndex: "channel",
  },
  {
    title: "AMOUNT",
    dataIndex: "amount",
  },
  {
    title: "COMMISSION",
    dataIndex: "commission",
  },
  {
    title: "TRANS.STATUS",
    dataIndex: "status",
    render: (tag) => {
      let color = tag ? "volcano" : "green";
      let text = tag ? "Failed" : "Success";

      return (
        <Tag color={color} key={tag}>
          {text}
        </Tag>
      );
    },
  },
  {
    title: "PAYOUT STATUS",
    dataIndex: "status2",
    render: (tag) => {
      let color = tag ? "volcano" : "green";
      let text = tag ? "Failed" : "Success";

      return (
        <Tag color={color} key={tag}>
          {text}
        </Tag>
      );
    },
  },
  {
    title: "DATE",
    dataIndex: "createdAt",
  },
];

const data = [];
for (let i = 0; i < 46; i++) {
  data.push({
    key: i,
    ref: `PDA-12Y9${i}`,
    details: "Lorem Ipsum",
    channel: "Credit Card",
    amount: "₦20,000",
    commission: "3% (₦600)",
    status: i % 2 === 0,
    status2: i % 2 === 0,
    createdAt: "12/08/2020",
  });
}

class MerchantSettlementsTable extends React.Component {
  state = {
    selectedRowKeys: [], // Check here to configure the default column
    loading: false,
  };

  start = () => {
    this.setState({ loading: true });
    // ajax request after empty completing
    setTimeout(() => {
      this.setState({
        selectedRowKeys: [],
        loading: false,
      });
    }, 1000);
  };

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  render() {
    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;
    return (
      <div>
        <div style={{ marginBottom: 16 }}>
          {/* <Button
            type="primary"
            onClick={this.start}
            disabled={!hasSelected}
            loading={loading}
          >
            Reload
          </Button> */}
          <span style={{ marginLeft: 8 }}>
            {hasSelected ? `Selected ${selectedRowKeys.length} items` : ""}
          </span>
        </div>
        <Table
          rowSelection={rowSelection}
          columns={columns}
          dataSource={data}
        />
      </div>
    );
  }
}
export default MerchantSettlementsTable;
