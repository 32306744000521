/** @format */

import React, { useEffect } from "react";
import { Form, Input, Button, Spin, message, Col, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  changeMerchantValue,
  configureMerchant,
} from "../../store/merchantSlice";

export default function MerchantConfig() {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const configMerchantLoading = useSelector(
    (state) => state.merchant.configMerchantLoading
  );
  const configMerchantError = useSelector(
    (state) => state.merchant.configMerchantError
  );
  const configMerchantStatus = useSelector(
    (state) => state.merchant.configMerchantStatus
  );

  useEffect(() => {
    if (configMerchantStatus === "failed") {
      if (configMerchantError) {
        message.error(configMerchantError);
      } else {
        message.error("Error configuring merchant, try again!");
      }
    }
    if (configMerchantStatus === "success") {
      message.success("Merchant configured!");
    }
    dispatch(changeMerchantValue("configMerchantError", ""));
  }, [configMerchantStatus, configMerchantError, dispatch]);
  return (
    <Row gutter={[16, 16]} style={{ width: "100%", padding: 20 }}>
      <Col xs={24} md={24} lg={12} xl={12} style={{ margin: "auto" }}>
        <div className='text-section'>
          <h1>DB Config</h1> <Spin spinning={configMerchantLoading} />
          <Form
            form={form}
            layout='vertical'
            initialValues={{}}
            size='large'
            onFinish={(values) => {
              dispatch(
                configureMerchant({
                  ...values,
                  merchant_code: "PRM-939139-LIN-LI",
                })
              );
            }}>
            <Form.Item
              required
              name='db_host'
              rules={[
                {
                  required: true,
                  message: "DB Host is required!",
                },
              ]}
              className='my-2'>
              <Input placeholder='DB Host' />
            </Form.Item>
            <Form.Item
              required
              name='db_username'
              rules={[
                {
                  required: true,
                  message: "DB Username is required!",
                },
              ]}
              className='my-2'>
              <Input placeholder='DB Username' />
            </Form.Item>
            <Form.Item
              required
              name='db_password'
              rules={[
                {
                  required: true,
                  message: "DB Password is required!",
                },
              ]}
              className='my-2'>
              <Input placeholder='DB Password' type='password' />
            </Form.Item>
            <Form.Item
              required
              name='db_port'
              rules={[
                {
                  required: true,
                  message: "DB Port is required!",
                },
              ]}
              className='my-2'>
              <Input placeholder='DB Port' />
            </Form.Item>
            <Form.Item
              required
              name='domain'
              rules={[
                {
                  required: true,
                  message: "DB Domain is required!",
                },
              ]}
              className='my-2'>
              <Input placeholder='DB Domain' />
            </Form.Item>
            <Form.Item
              required
              name='api_endpoint'
              rules={[
                {
                  required: true,
                  message: "EndPoint is required!",
                },
              ]}
              className='my-2'>
              <Input placeholder='EndPoint' />
            </Form.Item>

            <Form.Item className='my-3'>
              <Button
                type='primary'
                color='#404c96'
                style={{ backgroundColor: "#404c96" }}
                htmlType='submit'>
                ADD
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Col>
    </Row>
  );
}
