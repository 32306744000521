/** @format */

import React from "react";
import { Line } from "react-chartjs-2";

export default function RevenueChart({ graph_report }) {
  const data = {
    labels: graph_report?.labels,
    datasets: [
      {
        label: "Revenue",
        backgroundColor: "rgb(255, 99, 132)",
        borderColor: "rgb(255, 99, 132)",
        data: graph_report?.data,
      },
    ],
  };
  return (
    <div style={{ width: "100%", height: 200 }}>
      <Line data={data} />
    </div>
  );
}
