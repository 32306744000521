/** @format */

import React, { useState } from "react";
import NavHeader from "../../components/NavHeader/NavHeader";
import "./style.css";
import { Menu } from "antd";
import UsersList from "../UsersList/UsersList";
import RolesList from "../RolesList/RolesList";

export default function Users() {
  const [current, setCurrent] = useState("one");
  return (
    <div>
      <NavHeader
        children={
          <Menu
            selectedKeys={[current]}
            mode='horizontal'
            style={{
              backgroundColor: "transparent",
              borderBottomWidth: 0,
              marginRight: "auto",
              marginLeft: 20,
            }}>
            <Menu.Item key='one' onClick={(key) => setCurrent(key.key)}>
              Manage Users
            </Menu.Item>
            <Menu.Item key='two' onClick={(key) => setCurrent(key.key)}>
              Manage Roles
            </Menu.Item>
          </Menu>
        }
      />
      <UsersList visible={current === "one"} />
      <RolesList visible={current === "two"} />
    </div>
  );
}
