/** @format */

import React, { useEffect } from "react";
import "./style.css";
import { List, Collapse, Spin } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import {
  loadNotifications,
  readNotification,
} from "../../store/dashboardSlice";

export default function Notifications() {
  const { Panel } = Collapse;

  const notifications = useSelector((state) => state.dashboard.notifications);
  const notificationsLoading = useSelector(
    (state) => state.dashboard.notificationsLoading
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadNotifications());
  }, [dispatch]);
  function callback(key) {
    console.log(key);
  }

  return (
    <div>
      <div className='large-header pt-30 my-30  '>
        Notification
        <Spin spinning={notificationsLoading} />
      </div>
      <div className='p-20 bg-white'>
        <div className='large-header  '>Notification</div>
        <div className='primary-header my-10'>
          Here are the lastest report on your account
        </div>

        <Collapse onChange={callback} bordered={false}>
          {notifications.length === 0 && (
            <div
              style={{
                height: 300,
                flexDirection: "column",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <p>Your notifications will show here.</p>
            </div>
          )}
          {notifications.map((item, index) => (
            <Panel
              showArrow={false}
              style={{ backgroundColor: "white" }}
              header={
                <List.Item
                  onClick={() => {
                    dispatch(readNotification(item.id));
                  }}>
                  <List.Item.Meta
                    title={item.title}
                    description={new Date().toDateString()}
                  />

                  <div>
                    <FontAwesomeIcon icon={faAngleRight} />
                  </div>
                </List.Item>
              }
              key={(index + 1).toString()}>
              <p>{item.log}</p>
            </Panel>
          ))}
        </Collapse>
      </div>
    </div>
  );
}
