/** @format */

import React, { useEffect, useState } from "react";
import { Table, Tag } from "antd";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loadMerchants } from "../store/dashboardSlice";
// import _ from "lodash";
import FuzzySearch from "fuzzy-search";

export default function MerchantsTable({ searchQuery }) {
  //Data
  const dispatch = useDispatch();
  const [current, setCurrent] = useState(1);

  useEffect(() => {
    dispatch(loadMerchants(current));
  }, [dispatch, current]);

  const changePage = async (page) => {
    setCurrent(page);
    dispatch(loadMerchants(page));
  };
  const merchantsPagination = useSelector(
    (state) => state.dashboard.merchantsPagination
  );
  const merchants = useSelector((state) => state.dashboard.merchants);
  const merchantsData = merchants.map((i) => {
    return {
      key: i.uuid,
      name: i,
      email: i.business_email,
      phone: i.business_phone_number,
      verified: i.verified,
      status: i.status,
      subscription_status: i.subscription_status,
      createdAt: new Date(i.created_at).toLocaleDateString(),
    };
  });

  const searcher = new FuzzySearch(merchantsData, [
    "name.business_name",
    "email",
    "phone",
  ]);

  let searchResult = merchantsData;
  if (searchQuery) {
    searchResult = searcher.search(searchQuery);
  }
  //Data

  const columns = [
    {
      title: "COMPANY NAME",
      dataIndex: "name",
      render: (text) => {
        return (
          <Link
            to={`/company-admin/merchants/${text.merchant_code}`}
            key={text.business_name}>
            {text.business_name}
          </Link>
        );
      },
    },
    {
      title: "EMAIL ADDRESS",
      dataIndex: "email",
    },
    {
      title: "PHONE NUMBER",
      dataIndex: "phone",
    },
    {
      title: "VERIFIED",
      dataIndex: "verified",
      render: (tag) => {
        let color = tag.toLowerCase() === "no" ? "volcano" : "green";

        return (
          <Tag color={color} key={tag} className='capitalize'>
            {tag}
          </Tag>
        );
      },
    },
    {
      title: "STATUS",
      dataIndex: "status",
      render: (tag) => {
        let color = tag.toLowerCase() === "inactive" ? "volcano" : "green";

        return (
          <Tag color={color} key={tag} className='capitalize'>
            {tag}
          </Tag>
        );
      },
    },
    {
      title: "SUBSCRIPTION",
      dataIndex: "subscription_status",
      render: (tag) => {
        let color = tag.toLowerCase() === "inactive" ? "volcano" : "green";

        return (
          <Tag color={color} key={tag} className='capitalize'>
            {tag}
          </Tag>
        );
      },
    },
    {
      title: "CREATED DATE",
      dataIndex: "createdAt",
    },
  ];

  const data = [];
  for (let i = 0; i < 46; i++) {
    data.push({
      key: i,
      name: `BuyFood Africa ${i}`,
      email: "hello@buyfood.com",
      phone: "08082034738",
      verified: i % 2 === 0,
      status: "Active",
      createdAt: "12/08/2020",
    });
  }

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;
  return (
    <div>
      <div style={{ marginBottom: 16 }}>
        {/* <Button
        type="primary"
        onClick={this.start}
        disabled={!hasSelected}
        loading={loading}
      >
        Reload
      </Button> */}
        <span style={{ marginLeft: 8 }}>
          {hasSelected ? `Selected ${selectedRowKeys.length} items` : ""}
        </span>
      </div>
      <Table
        scroll={{ x: true }}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={searchResult}
        pagination={{
          current,
          defaultCurrent: merchantsPagination?.current_page,
          total: merchantsPagination?.total,
          onChange: (page, pageSize) => changePage(page),
          pageSize: Number(merchantsPagination?.per_page),
          showSizeChanger: false,
        }}
      />
    </div>
  );
}
