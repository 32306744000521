/** @format */

import React, { useEffect, useState } from "react";
import { message, Table, Tag } from "antd";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import FuzzySearch from "fuzzy-search";
import { useParams } from "react-router-dom";
import { changeMerchantValue, getMerchantBikes } from "../store/merchantSlice";

const columns = [
  // {
  //   title: "BIKE NAME",
  //   dataIndex: "bike",
  // },
  {
    title: "RIDER",
    dataIndex: "name",
  },
  {
    title: "LOCATION",
    dataIndex: "location",
  },

  {
    title: "STATUS",
    dataIndex: "status",
    render: (tag) => {
      let color = tag.toLowerCase() === "inactive" ? "volcano" : "green";

      return (
        <Tag color={color} key={tag} className='capitalize'>
          {tag}
        </Tag>
      );
    },
  },
  {
    title: "CREATED DATE",
    dataIndex: "createdAt",
  },
];

const data = [];
for (let i = 0; i < 46; i++) {
  data.push({
    key: i,
    bike: "Bike " + i,
    name: `Adebayo Balogun ${i}`,
    location: "2 Anthony way, Lagos",
    status: i % 2 === 0,
    createdAt: "12/08/2020",
  });
}

export default function MerchantBikesTable({
  sortBy,
  filterProp,
  filterValue,
  searchQuery,
  // dateRange,
}) {
  const params = useParams();
  const dispatch = useDispatch();

  const bikesStatus = useSelector((state) => state.merchant.bikesStatus);
  const bikesPagination = useSelector(
    (state) => state.merchant.bikesPagination
  );
  const [current, setCurrent] = useState(1);

  useEffect(() => {
    dispatch(getMerchantBikes(params.merchantId, current));
  }, [dispatch, params.merchantId, current]);
  const changePage = async (page) => {
    setCurrent(page);
    dispatch(getMerchantBikes(params.merchantId, page));
  };
  const bikesError = useSelector((state) => state.merchant.bikesError);
  const bikes = useSelector((state) => state.merchant.bikes);
  const bikesData = bikes.map((i) => {
    return {
      key: i.id,
      bike: i.name,
      name: i.rider,
      location: i.location,
      phone: i.phone,
      status: i.status,
      createdAt: new Date(i.created_at).toLocaleDateString(),
    };
  });

  //sorting
  let sortedBikes = bikesData;

  sortedBikes = _.orderBy(bikesData, ["bike"], [sortBy]);
  let filteredBikes = sortedBikes;

  if (filterProp && filterValue) {
    filteredBikes = _.filter(sortedBikes, function (o) {
      return o[filterProp].toLowerCase() === filterValue.toLowerCase();
    });
  }
  const searcher = new FuzzySearch(filteredBikes, [
    "bike",
    "phone",
    "name",
    "email",
  ]);

  let searchResult = filteredBikes;
  if (searchQuery) {
    searchResult = searcher.search(searchQuery);
  }
  //sorting

  useEffect(() => {
    if (bikesStatus === "failed") {
      if (bikesError) {
        message.error(bikesError);
      } else {
        message.error("Could not load merchant bikes. Try Reload!");
      }
    }
    dispatch(changeMerchantValue("bikesError", ""));
  }, [bikesStatus, bikesError, dispatch]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;
  return (
    <div>
      <div style={{ marginBottom: 16 }}>
        <span style={{ marginLeft: 8 }}>
          {hasSelected ? `Selected ${selectedRowKeys.length} items` : ""}
        </span>
      </div>
      <Table
        scroll={{ x: true }}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={searchResult}
        pagination={{
          current,
          defaultCurrent: bikesPagination?.current_page,
          total: bikesPagination?.total,
          onChange: (page, pageSize) => changePage(page),
          pageSize: Number(bikesPagination?.per_page),
          showSizeChanger: false,
        }}
      />
    </div>
  );
}
