/** @format */

import React, { useEffect, useState } from "react";
import { Button, message, Spin, Table, Tag } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Input, Modal, Select, Form } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { time_ago } from "../assets/js/nFormatter";
import {
  changeDashboardValue,
  deleteUser,
  loadRoles,
  loadUsers,
  updateUser,
} from "../store/dashboardSlice";
import _ from "lodash";
import FuzzySearch from "fuzzy-search";

const { Option } = Select;
const UsersListTable = ({ sortBy, filterProp, filterValue, searchQuery }) => {
  const dispatch = useDispatch();

  const users = useSelector((state) => state.dashboard.users);
  const usersPagination = useSelector(
    (state) => state.dashboard.usersPagination
  );

  const updateUserStatus = useSelector(
    (state) => state.dashboard.updateUserStatus
  );
  const userupdateLoading = useSelector(
    (state) => state.dashboard.userupdateLoading
  );
  const deleteUserStatus = useSelector(
    (state) => state.dashboard.deleteUserStatus
  );
  const userupdateError = useSelector(
    (state) => state.dashboard.userupdateError
  );
  const userdeleteLoading = useSelector(
    (state) => state.dashboard.userdeleteLoading
  );
  const userdeleteError = useSelector(
    (state) => state.dashboard.userdeleteError
  );
  const roles = useSelector((state) => state.dashboard.roles);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isEditUserModalVisible, setIsEditUserModalVisible] = useState(false);
  // const [okModal, setOkModal] = React.useState(false);
  const [user, setUser] = useState(null);
  const [current, setCurrent] = useState(1);

  useEffect(() => {
    dispatch(loadUsers(current));
    dispatch(loadRoles(1));
  }, [dispatch, current]);

  useEffect(() => {
    if (updateUserStatus === "success") {
      setIsEditUserModalVisible(false);
      message.success("User updated successfully!");
    }
    if (updateUserStatus === "failed") {
      if (userupdateError) {
        message.error(userupdateError);
      } else {
        message.error("Could not update user, Try again!");
      }
    }
    dispatch(changeDashboardValue("updateUserStatus", "pending"));

    if (deleteUserStatus === "failed") {
      if (userdeleteError) {
        message.error(userdeleteError);
      } else {
        message.error("Could not delete user, Try again!");
      }
    }
    if (deleteUserStatus === "success") {
      message.success("User deleted successfully!");
    }
    dispatch(changeDashboardValue("deleteUserStatus", "pending"));
    dispatch(changeDashboardValue("userupdateError", ""));
    dispatch(changeDashboardValue("userdeleteError", ""));
  }, [
    updateUserStatus,
    deleteUserStatus,
    dispatch,
    userupdateError,
    userdeleteError,
  ]);

  const changePage = async (page) => {
    setCurrent(page);
    dispatch(loadUsers(page));
  };
  const userData = users.map((i) => {
    return {
      key: i.id,
      name: i.full_name,
      age: 32,
      email: i.email,
      userName: i.username,
      role: i.role,
      lastSeen: time_ago(i.updated_at),
      status: i.status,
      edit: i,
      delete: i,
    };
  });

  let sortedUsers = userData;

  sortedUsers = _.orderBy(userData, ["name"], [sortBy]);
  let filteredUsers = sortedUsers;

  if (filterProp && filterValue) {
    filteredUsers = _.filter(sortedUsers, [filterProp, filterValue]);
  }
  const searcher = new FuzzySearch(filteredUsers, [
    "username",
    "name",
    "age",
    "email",
  ]);

  let searchResult = filteredUsers;
  if (searchQuery) {
    searchResult = searcher.search(searchQuery);
  }

  const columns = [
    {
      title: "FULL NAME",
      dataIndex: "name",
    },
    {
      title: "EMAIL ADDRESS",
      dataIndex: "email",
    },
    {
      title: "ROLE",
      dataIndex: "role",
    },
    {
      title: "USERNAME",
      dataIndex: "userName",
    },
    {
      title: "ACTIVE USER",
      dataIndex: "status",
      render: (tag) => {
        let color = tag?.toLowerCase() === "inactive" ? "volcano" : "green";

        return (
          <Tag color={color} key={tag} className='capitalize'>
            {tag}
          </Tag>
        );
      },
    },

    {
      title: "",
      dataIndex: "edit",
      render: (value) => {
        return (
          <FontAwesomeIcon
            icon={faPen}
            color='#9E9E9E'
            className='cursor'
            onClick={() => {
              setUser(value);
              setIsEditUserModalVisible(true);
            }}
          />
        );
      },
    },
    {
      title: () => <Spin spinning={userdeleteLoading} />,
      dataIndex: "delete",
      render: (value) => {
        return (
          <FontAwesomeIcon
            icon={faTrash}
            color='#F95050'
            className='cursor'
            onClick={() => {
              // setUser(value);
              // setIsEditUserModalVisible(true);
              dispatch(deleteUser(value.uuid));
            }}
          />
        );
        // deleteUser
      },
    },
  ];

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;
  return (
    <div>
      <div style={{ marginBottom: 16 }}>
        <span style={{ marginLeft: 8 }}>
          {hasSelected ? `Selected ${selectedRowKeys.length} items` : ""}
        </span>
      </div>
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={searchResult}
        pagination={{
          current,
          defaultCurrent: usersPagination?.current_page,
          total: usersPagination?.total,
          onChange: (page, pageSize) => changePage(page),
          pageSize: Number(usersPagination?.per_page),
          showSizeChanger: false,
        }}
      />

      <Modal
        title='Update User'
        visible={isEditUserModalVisible}
        footer={null}
        onCancel={() => setIsEditUserModalVisible(false)}>
        <Form
          name='basic'
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{
            full_name: user?.full_name,
            email: user?.email,
            role_id: user?.role_id,
            username: user?.username,
          }}
          onFinish={(values) => {
            dispatch(updateUser(user.uuid, values));
          }}
          onFinishFailed={(errorInfo) => {}}
          autoComplete='off'>
          <Form.Item label='Full Name' name='full_name'>
            <Input />
          </Form.Item>
          <Form.Item label='Username' name='username'>
            <Input />
          </Form.Item>

          <Form.Item label='Email Address' name='email'>
            <Input />
          </Form.Item>
          <Form.Item name='role_id' label='Role'>
            <Select
              placeholder='Select a role'
              defaultValue={user?.role_id}
              // allowClear
            >
              {roles.map((role) => (
                <Option value={role.id}>{role.name}</Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type='primary' htmlType='submit'>
              UPDATE USER
            </Button>
            <Spin spinning={userupdateLoading} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default UsersListTable;
