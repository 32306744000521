/** @format */

import React, { useEffect, useState } from "react";
import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faClipboardList,
  faCog,
  faHome,
  faListAlt,
  faMapMarkerAlt,
  faThLarge,
  faUser,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as Logo } from "../../assets/prihmex-logo2.svg";
import { Button, Dropdown, Menu } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import Calendar from "react-calendar";
import OverView from "../OverView/OverView";
import MerchantsScreen from "../MerchantsScreen/MerchantsScreen";
import {
  NavLink,
  Redirect,
  Route,
  Switch,
  useRouteMatch,
} from "react-router-dom";
import Transactions from "../Transactions/Transactions";
import Tracking from "../Tracking/Tracking";
import Users from "../Users/Users";
import { getLoggedInUser, logUserOut } from "../../store/authSlice";
import { useDispatch, useSelector } from "react-redux";
import Subscriptions from "../Subscriptions/Subscriptions";
import ProfileScreen from "../ProfileScreen/ProfileScreen";
import Notifications from "../Notifications/Notifications";
import { loadNotifications } from "../../store/dashboardSlice";
import Features from "../Features/Features";

export default function Dashboard() {
  const [value, onChange] = useState(new Date());
  const dispatch = useDispatch();
  const NavIcon = ({ icon, text, link }) => {
    return (
      <NavLink to={link} className='icon-item'>
        <div className='icon-badge'>
          <FontAwesomeIcon icon={icon} className='icon-color' size='lg' />
        </div>
        <p className='primary-p mt-10'>{text}</p>
      </NavLink>
    );
  };
  let { path, url } = useRouteMatch();
  const notifications = useSelector((state) => state.dashboard.notifications);
  const user = getLoggedInUser();
  const menu = (
    <Menu>
      {notifications.length === 0 && <Menu.Item>No notifications</Menu.Item>}
      {notifications.map((item) => (
        <Menu.Item>
          <NavLink to={`${url}/notifications`}>
            <strong>{item.title}</strong>
            <br />
            {new Date(item.created_at).toDateString()}
          </NavLink>
        </Menu.Item>
      ))}
    </Menu>
  );

  useEffect(() => {
    dispatch(loadNotifications());
  }, [dispatch]);

  if (!user) return <Redirect to='/company-admin/login' />;
  return (
    <div id='dashboard'>
      <div className='left-bar py-3'>
        <NavLink to='/company-admin'>
          <Logo />
        </NavLink>
        <nav>
          <NavIcon link={`${url}`} icon={faHome} text='Dashboard' />
          <NavIcon
            link={`${url}/merchants`}
            icon={faThLarge}
            text='Merchants'
          />
          <NavIcon
            link={`${url}/transactions`}
            icon={faClipboardList}
            text='Transactions'
          />
          <NavIcon
            link={`${url}/tracking`}
            icon={faMapMarkerAlt}
            text='Tracking'
          />
          {/* <NavIcon
            link={`${url}/features`}
            icon={faListAlt}
            text='Features'
          /> */}
          <NavIcon
            link={`${url}/subscriptions`}
            icon={faListAlt}
            text='Subscriptions'
          />
          <NavIcon
            link={`${url}/management`}
            icon={faUsers}
            text={`User \n Management`}
          />
        </nav>
        <Button
          type='link'
          className='logout-text'
          danger
          size='large'
          onClick={() => {
            dispatch(logUserOut());
          }}
          icon={<LogoutOutlined />}>
          Logout
        </Button>
      </div>
      <div className='middle-bar'>
        <Switch>
          <Route path={`${path}/management`}>
            <Users />
          </Route>
          <Route path={`${path}/merchants`}>
            <MerchantsScreen />
          </Route>
          <Route path={`${path}/transactions`}>
            <Transactions />
          </Route>
          <Route path={`${path}/tracking`}>
            <Tracking />
          </Route>
          <Route path={`${path}/features`}>
            <Features />
          </Route>
          <Route path={`${path}/subscriptions`}>
            <Subscriptions />
          </Route>
          <Route path={`${path}/profile`}>
            <ProfileScreen />
          </Route>
          <Route path={`${path}/notifications`}>
            <Notifications />
          </Route>
          <Route exact path={path}>
            <OverView />
          </Route>
        </Switch>
      </div>
      <div className='right-bar'>
        <div className='row-between'>
          <NavLink to={`${url}/profile`}>
            <div className='icon-badge'>
              <FontAwesomeIcon icon={faCog} color='#ADADAD' size='lg' />
            </div>
          </NavLink>
          <Dropdown overlay={menu}>
            <NavLink className='ant-dropdown-link' to={`${url}/notifications`}>
              <div className='icon-badge '>
                {notifications.length > 0 && (
                  <div
                    style={{
                      width: 10,
                      height: 10,
                      backgroundColor: "cornflowerblue",
                      position: "absolute",
                      top: 5,
                      left: 5,
                      borderRadius: 10 / 2,
                    }}></div>
                )}

                <FontAwesomeIcon icon={faBell} color='#ADADAD' size='lg' />
              </div>
            </NavLink>
          </Dropdown>
        </div>
        <div className='profile-preview'>
          <NavLink to={`${url}/profile`}>
            <div
              className='pic-avatar'
              style={{ backgroundImage: `url(${process.env.REACT_APP_IMAGE + user?.profile_image})` }}>
              {!user.profile_image && (
                <FontAwesomeIcon icon={faUser} size='4x' color='#ADADAD' />
              )}
            </div>
            <div className='text-center mb-50'>
              <h3 className='primary-header mt-10'>{user.full_name}</h3>
              <small className='primary-sub-header'>{user.role}</small>
            </div>
          </NavLink>
        </div>
        <div className='separator' />
        <div className='site-calendar-customize-header-wrapper'>
          <Calendar onChange={onChange} value={value} />
        </div>
        <div className='separator' />
        <div className='pending-settlement'>
          <h2 className='white'>Our mission statement</h2>
          <p className='white'>
            To become the backbone of the logistics industry in Africa.
          </p>
        </div>
      </div>
    </div>
  );
}
