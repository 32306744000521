/** @format */

import React, { useState } from "react";
import "./style.css";
import { Button, Input, Modal, DatePicker, Select, Form, Spin } from "antd";
import { Row, Col } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faFilter,
  faSort,
} from "@fortawesome/free-solid-svg-icons";
import { Menu, Dropdown, message, Space } from "antd";
import UsersListTable from "../../components/UsersListTable";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { addUser, changeDashboardValue } from "../../store/dashboardSlice";

const { Search } = Input;
const { RangePicker } = DatePicker;
const { Option } = Select;

export default function UsersList({ visible = false }) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const userAddLoading = useSelector((state) => state.dashboard.userAddLoading);
  const addUserError = useSelector((state) => state.dashboard.addUserError);
  const addUserStatus = useSelector((state) => state.dashboard.addUserStatus);
  const roles = useSelector((state) => state.dashboard.roles);

  const [visiblePop, setVisiblePop] = React.useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [okModal, setOkModal] = React.useState(false);
  const [sortBy, setSortBy] = useState("asc");
  const [filterProp, setFilterProp] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [dateRange, setDateRange] = useState(null);

  useEffect(() => {
    if (addUserStatus === "success") {
      form.resetFields();
      setIsModalVisible(false);
      setOkModal(true);
    }
    if (addUserStatus === "failed") {
      if (addUserError) {
        message.error(addUserError);
      } else {
        message.error("Could not add user, Try again!");
      }
    }
    dispatch(changeDashboardValue("addUserStatus", "pending"));
    dispatch(changeDashboardValue("addUserError", ""));
  }, [addUserStatus, dispatch, addUserError, form]);

  const handleOk = () => {
    setConfirmLoading(true);
    setTimeout(() => {
      setVisiblePop(false);
      setConfirmLoading(false);
    }, 2000);
  };
  const handleCancel = () => {
    setVisiblePop(false);
  };

  const menu = (
    <Menu>
      <Menu.Item key='1' onClick={() => setSortBy("asc")}>
        Alphabetically (A-Z)
      </Menu.Item>
      <Menu.Item key='2' onClick={() => setSortBy("desc")}>
        Alphabetically (Z-A)
      </Menu.Item>
    </Menu>
  );
  const menuTwo = (
    <Menu>
      <Menu.Item
        key='2'
        onClick={() => {
          setFilterProp("");
          setFilterValue("");
        }}>
        All
      </Menu.Item>

      <Menu.Item
        key='2'
        onClick={() => {
          setFilterProp("status");
          setFilterValue("Active");
        }}>
        Status - Active
      </Menu.Item>
      <Menu.Item
        key='3'
        onClick={() => {
          setFilterProp("status");
          setFilterValue("Inactive");
        }}>
        Status - Inactive
      </Menu.Item>
    </Menu>
  );

  if (!visible) return null;

  return (
    <div>
      <div className='large-header secondary pt-30'>Users</div>
      <div className='primary-header'>Here are the list of users</div>
      <Row
        gutter={16}
        style={{ marginTop: 20, justifyContent: "space-between" }}>
        <Col className='gutter-row' span={6}>
          <Search
            placeholder='input search text'
            onChange={(e) => {
              setSearchQuery(e.target.value);
            }}
            enterButton
            color='green'
            style={{ backgroundColor: "#5322DB" }}
          />
        </Col>
        <Col className='gutter-row'>
          <Space wrap>
            <Dropdown overlay={menu}>
              <Button
                type='text'
                style={{ backgroundColor: "#7677AE", color: "white" }}
                className='text-white br-5'>
                Sort{" "}
                <FontAwesomeIcon icon={faSort} className='ml-10 text-white' />
              </Button>
            </Dropdown>
            <Dropdown overlay={menuTwo}>
              <Button
                type='text'
                style={{ backgroundColor: "#5322DB", color: "#ffffff" }}
                className='text-white br-5'>
                Filter{" "}
                <FontAwesomeIcon icon={faFilter} className='ml-10 text-white' />
              </Button>
            </Dropdown>
            <Button
              type='text'
              style={{ backgroundColor: "#131573", color: "#ffffff" }}
              className='text-white br-5'
              onClick={() => setIsModalVisible(true)}>
              Create new user
            </Button>
          </Space>
        </Col>
      </Row>
      <UsersListTable
        sortBy={sortBy}
        filterProp={filterProp}
        filterValue={filterValue}
        searchQuery={searchQuery}
        dateRange={dateRange}
      />
      <Modal
        title='Filter By Date'
        visible={visiblePop}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}>
        <RangePicker
          onChange={(date, dateString) => {
            if (dateString) {
              if (dateString[0] && dateString[1]) {
                setDateRange(dateString);
              }
            }
          }}
        />
      </Modal>
      <Modal
        title='Add new user'
        visible={isModalVisible}
        footer={null}
        onCancel={() => setIsModalVisible(false)}>
        <Form
          form={form}
          name='basic'
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={(values) => {
            dispatch(
              addUser({
                ...values,
                profile_image: "http://placeimg.com/640/480/people",
              })
            );
          }}
          onFinishFailed={(errorInfo) => {}}
          autoComplete='off'>
          <Form.Item
            label='Full Name'
            name='full_name'
            rules={[
              { required: true, message: "Please input your full name!" },
            ]}>
            <Input />
          </Form.Item>
          <Form.Item
            label='Username'
            name='username'
            rules={[
              { required: true, message: "Please input your username!" },
            ]}>
            <Input />
          </Form.Item>

          <Form.Item
            label='Email Address'
            name='email'
            rules={[
              {
                required: true,
                type: "email",
              },
            ]}>
            <Input />
          </Form.Item>
          <Form.Item
            name='role_id'
            label='Role'
            rules={[
              {
                required: true,
              },
            ]}>
            <Select
              placeholder='Select a role'
              onChange={(value) => {}}
              allowClear
              defaultValue={roles && roles[0] && roles[0].id}>
              {roles.map((role) => (
                <Option value={role.id}>{role.name}</Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type='primary' htmlType='submit'>
              ADD
            </Button>
            <Spin spinning={userAddLoading} />
          </Form.Item>
        </Form>
        {/* <Input
          size="large"
          placeholder="Full Name"
          onChange={(e) => {
            setFull_name(e.target.value);
          }}
        />
        <br />
        <br />
        <Input
          size="large"
          placeholder="Username"
          onChange={(e) => {
            setUsername(e.target.value);
          }}
        />
        <br />
        <br />
        <Input
          size="large"
          placeholder="Email Address"
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
        <br />
        <br />
        <Select
          defaultValue="Admin"
          style={{ width: "100%" }}
          size="large"
          onChange={(value) => setRole_id(value)}
        >
          {roles.map((role) => (
            <Option value={role.id}>{role.name}</Option>
          ))}
        </Select> */}
      </Modal>
      <Modal visible={okModal} footer={null} style={{ padding: 20 }}>
        <div className='jcc'>
          <FontAwesomeIcon icon={faCheckCircle} size='10x' color='#94FF92' />
          <h1>User added Successfully!</h1>
          {/* <p className="text-center text-light">
            https-admin-primhex.com/user-verification/user-id?293uwiw0-3939Wieio-3u2s
          </p>
          <FontAwesomeIcon
            icon={faClone}
            color="grey"
            size="lg"
            className="my-10"
          /> */}
          <Button
            type='ghost'
            style={{ backgroundColor: "#6BAF43", color: "white" }}
            size='large'
            className='br-10'
            onClick={() => setOkModal(false)}>
            CLOSE
          </Button>
        </div>
      </Modal>
    </div>
  );
}
