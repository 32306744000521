/** @format */

import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";

const slice = createSlice({
  name: "merchant",
  initialState: {
    merchantStatsError: "",
    merchantStatsStatus: "pending",
    merchantStatsLoading: false,
    merchantStats: {},
    merchant: {},
    merchantLoading: false,
    merchantError: "",
    merchantStatus: "pending",
    transactionsPagination: {},
    transactions: [],
    transactionsLoading: false,
    transactionsError: "",
    transactionsStatus: "pending",
    ridersPagination: {},
    riders: [],
    ridersLoading: false,
    ridersError: "",
    ridersStatus: "pending",
    bikesPagination: {},
    bikes: [],
    bikesLoading: false,
    bikesError: "",
    bikesStatus: "pending",
    usersPagination: {},
    users: [],
    usersLoading: false,
    usersError: "",
    usersStatus: "pending",
    configuredMerchant: {},
    configMerchantLoading: false,
    configMerchantError: "",
    configMerchantStatus: "pending",
  },
  reducers: {
    changeValue: (dashboard, action) => {
      dashboard[action.payload.key] = action.payload.value;
    },
    configMerchantStarted: (dashboard, action) => {
      dashboard.configMerchantStatus = "pending";
      dashboard.configMerchantLoading = true;
    },
    configMerchantSuccess: (dashboard, action) => {
      dashboard.configuredMerchant = action.payload;
      dashboard.configMerchantStatus = "success";
      dashboard.configMerchantLoading = false;
    },
    configMerchantFailed: (dashboard, action) => {
      dashboard.configMerchantStatus = "failed";
      dashboard.configMerchantLoading = false;
      dashboard.configMerchantError = action.payload.response?.data?.message;
    },
    merchantStatsRequested: (dashboard, action) => {
      dashboard.merchantStatsStatus = "pending";
      dashboard.merchantStatsLoading = true;
    },
    merchantStatsReceived: (dashboard, action) => {
      dashboard.merchantStats = action.payload;
      dashboard.merchantStatsStatus = "success";
      dashboard.merchantStatsLoading = false;
    },
    merchantStatsRequestFailed: (dashboard, action) => {
      dashboard.merchantStatsStatus = "failed";
      dashboard.merchantStatsLoading = false;
      dashboard.merchantStatsError = action.payload.response?.data?.message;
    },
    merchantRequested: (dashboard, action) => {
      dashboard.merchantStatus = "pending";
      dashboard.merchantLoading = true;
    },
    merchantReceived: (dashboard, action) => {
      dashboard.merchant = action.payload;
      dashboard.merchantStatus = "success";
      dashboard.merchantLoading = false;
    },
    merchantRequestFailed: (dashboard, action) => {
      dashboard.merchantStatus = "failed";
      dashboard.merchantLoading = false;
      dashboard.merchantError = action.payload.response?.data?.message;
    },
    usersRequested: (dashboard, action) => {
      dashboard.usersStatus = "pending";
      dashboard.usersLoading = true;
    },
    usersReceived: (dashboard, action) => {
      dashboard.usersPagination = action.payload.pagination;
      dashboard.users = action.payload.data;
      dashboard.usersStatus = "success";
      dashboard.usersLoading = false;
    },
    usersRequestFailed: (dashboard, action) => {
      dashboard.usersStatus = "failed";
      dashboard.usersLoading = false;
      dashboard.usersError = action.payload.response?.data?.message;
    },
    bikesRequested: (dashboard, action) => {
      dashboard.bikesStatus = "pending";
      dashboard.bikesLoading = true;
    },
    bikesReceived: (dashboard, action) => {
      dashboard.bikesPagination = action.payload.pagination;
      dashboard.bikes = action.payload.data;
      dashboard.bikesStatus = "success";
      dashboard.bikesLoading = false;
    },
    bikesRequestFailed: (dashboard, action) => {
      dashboard.bikesStatus = "failed";
      dashboard.bikesLoading = false;
      dashboard.bikesError = action.payload.response?.data?.message;
    },
    ridersRequested: (dashboard, action) => {
      dashboard.ridersStatus = "pending";
      dashboard.ridersLoading = true;
    },
    ridersReceived: (dashboard, action) => {
      dashboard.ridersPagination = action.payload.pagination;
      dashboard.riders = action.payload.data;
      dashboard.ridersStatus = "success";
      dashboard.ridersLoading = false;
    },
    ridersRequestFailed: (dashboard, action) => {
      dashboard.ridersStatus = "failed";
      dashboard.ridersLoading = false;
      dashboard.ridersError = action.payload.response?.data?.message;
    },
    transactionsRequested: (dashboard, action) => {
      dashboard.transactionsStatus = "pending";
      dashboard.transactionsLoading = true;
    },
    transactionsReceived: (dashboard, action) => {
      dashboard.transactionsPagination = action.payload.pagination;
      dashboard.transactions = action.payload.data;
      dashboard.transactionsStatus = "success";
      dashboard.transactionsLoading = false;
    },
    transactionsRequestFailed: (dashboard, action) => {
      dashboard.transactionsStatus = "failed";
      dashboard.transactionsLoading = false;
      dashboard.transactionsError = action.payload.response?.data?.message;
    },
  },
});

export const {
  changeValue,
  transactionsReceived,
  transactionsRequestFailed,
  transactionsRequested,
  usersRequested,
  usersReceived,
  usersRequestFailed,
  bikesRequested,
  bikesReceived,
  bikesRequestFailed,
  ridersRequested,
  ridersReceived,
  ridersRequestFailed,
  merchantReceived,
  merchantRequestFailed,
  merchantRequested,
  configMerchantFailed,
  configMerchantStarted,
  configMerchantSuccess,
  merchantStatsRequested,
  merchantStatsReceived,
  merchantStatsRequestFailed,
} = slice.actions;

export default slice.reducer;

//Action creators
export const configureMerchant = (data) => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      url: `v1/merchants/${data.merchant_code}/config`,
      data,
      method: "post",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("primhex:token")}`,
      },
      onStart: configMerchantStarted.type,
      onSuccess: configMerchantSuccess.type,
      onError: configMerchantFailed.type,
    })
  );
};
export const getMerchantStats = (merchantId) => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      url: `v1/merchants/${merchantId}/stats`,
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("primhex:token")}`,
      },
      onStart: merchantStatsRequested.type,
      onSuccess: merchantStatsReceived.type,
      onError: merchantStatsRequestFailed.type,
    })
  );
};
export const getMerchant = (merchantId) => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      url: `v1/merchants/${merchantId}`,
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("primhex:token")}`,
      },
      onStart: merchantRequested.type,
      onSuccess: merchantReceived.type,
      onError: merchantRequestFailed.type,
    })
  );
};
export const getMerchantTransactions = (
  merchantId,
  page,
  start_date = "",
  end_date = ""
) => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      url: `v1/merchants/${merchantId}/transactions?start_date=${start_date}&end_date=${end_date}&page=${page}`,
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("primhex:token")}`,
      },
      onStart: transactionsRequested.type,
      onSuccess: transactionsReceived.type,
      onError: transactionsRequestFailed.type,
    })
  );
};
export const getMerchantRiders = (merchantId, page) => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      url: `v1/merchants/${merchantId}/riders?page=${page}`,
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("primhex:token")}`,
      },
      onStart: ridersRequested.type,
      onSuccess: ridersReceived.type,
      onError: ridersRequestFailed.type,
    })
  );
};
export const getMerchantBikes = (merchantId, page) => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      url: `v1/merchants/${merchantId}/bikes?page=${page}`,
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("primhex:token")}`,
      },
      onStart: bikesRequested.type,
      onSuccess: bikesReceived.type,
      onError: bikesRequestFailed.type,
    })
  );
};
export const getMerchantUsers = (merchantId, page) => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      url: `v1/merchants/${merchantId}/users?page=${page}`,
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("primhex:token")}`,
      },
      onStart: usersRequested.type,
      onSuccess: usersReceived.type,
      onError: usersRequestFailed.type,
    })
  );
};
export const changeMerchantValue = (key, value) => (dispatch, getState) => {
  dispatch({
    type: changeValue.type,
    payload: { key, value },
  });
};
