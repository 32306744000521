/** @format */

import React, { useEffect } from "react";
import { ReactComponent as GooglePlayBadge } from "../../assets/GooglePlayBadge.svg";
import { ReactComponent as AppStoreBadge } from "../../assets/AppStoreBadge.svg";
import { ReactComponent as Logo2 } from "../../assets/Logo2.svg";
import closeUpHandHoldingSmartphone from "../../assets/close-up-hand-holding-smartphone.png";
import "./style.css";
import { Form, Input, Button, Spin, message, Row, Col } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getLoggedInUser, logUserIn } from "../../store/authSlice";
import { Link, Redirect } from "react-router-dom";
import { useState } from "react";

export default function SignUp() {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [user, setUser] = useState(null);
  const status = useSelector((state) => state.user.status);
  const loading = useSelector((state) => state.user.loading);
  useEffect(() => {
    const user = getLoggedInUser();
    setUser(user);
  }, []);
  useEffect(() => {
    if (status === "failed") {
      message.error("Error logging in, try again!");
    }
  }, [status]);
  if (user) return <Redirect to='/company-admin' />;
  return (
    <div id='signUpScreen'>
      <div className='image-section '>
        <div className='px-100'>
          <p
            className='text-center '
            style={{
              fontFamily: "DM Sans",
              fontWeight: "bold",
              fontSize: 30,
              color: "#FFFFFF",
            }}>
            Moving your Products Across Borders
          </p>
          <p
            className='text-center mb-30'
            style={{
              fontFamily: "DM Sans",
              fontWeight: "500",
              fontSize: 16,
              color: "#FFFFFF",
            }}>
            Had some logistics hassle in the past. Worry no more, With our fast
            and fully automated system, you are guaranted a reliable system
          </p>
          <div className='d-flex justify-content-center aliqn-items-center'>
            <div className='mx-10'>
              <GooglePlayBadge />
            </div>
            <div className='mx-10'>
              <AppStoreBadge />
            </div>
          </div>
        </div>
        <img
          src={closeUpHandHoldingSmartphone}
          alt=''
          style={{ alignSelf: "flex-end" }}
        />
      </div>
      <div className='text-section'>
        <Logo2 style={{ marginBottom: 30 }} />
        <h1>Create an Account</h1> <Spin spinning={loading} />
        <p className='mb-5'>Tell us about you</p>
        <Form
          form={form}
          layout='vertical'
          initialValues={{}}
          size='large'
          onFinish={(values) => {
            dispatch(logUserIn(values.username, values.password));
          }}>
          <Row
            gutter={{ xs: 8, sm: 16, md: 24, lg: 64 }}
            className='pt-30 mt-20'>
            <Col xs={24} sm={24} md={24} lg={12}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Business Name",
                  },
                ]}
                name='business_name'>
                <Input placeholder='Business Name' size='large' width />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12}>
              <Form.Item
                required
                name='username'
                rules={[
                  {
                    required: true,
                    message: "Username is required!",
                  },
                ]}>
                <Input placeholder='Username' />
              </Form.Item>
            </Col>
          </Row>
          <Row
            gutter={{ xs: 8, sm: 16, md: 24, lg: 64 }}
            className='pt-30 mt-20'>
            <Col xs={24} sm={24} md={24} lg={12}>
              <Form.Item
                required
                name='email'
                rules={[
                  {
                    required: true,
                    message: "Email is required!",
                  },
                ]}>
                <Input placeholder='Email' type='email' />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Phone Number",
                  },
                ]}
                name='phone_number'>
                <Input placeholder='Phone Number' size='large' width />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            name='password'
            required
            rules={[
              {
                required: true,
                message: "Password is required!",
              },
            ]}
            className='my-2'>
            <Input.Password placeholder='input placeholder' />
          </Form.Item>
          <Form.Item className='my-3' wrapperCol>
            <Button
              type='primary'
              color='#404c96'
              style={{ backgroundColor: "#404c96", width: "100%" }}
              htmlType='submit'>
              Create Account
            </Button>
          </Form.Item>
        </Form>
        <div className='text-center'>
          Already have an account?{" "}
          <Link to='/company-admin/login'>Login into your account</Link>{" "}
        </div>
      </div>
    </div>
  );
}
