/** @format */

import React, { useState } from "react";
import { message, Table, Tag } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import FuzzySearch from "fuzzy-search";
import { useParams } from "react-router-dom";
import { getMerchantUsers } from "../store/merchantSlice";

export default function MerchantUsersTable({
  sortBy,
  filterProp,
  filterValue,
  searchQuery,
  dateRange,
}) {
  const columns = [
    {
      title: "FULL NAME",
      dataIndex: "name",
    },
    {
      title: "EMAIL ADDRESS",
      dataIndex: "email",
    },
    {
      title: "PHONE NUMBER",
      dataIndex: "phone",
    },
    // {
    //   title: "VERIFIED",
    //   dataIndex: "verified",
    //   render: (tag) => {
    //     let color = tag?.toLowerCase() === "no" ? "volcano" : "green";
    //     return (
    //       <Tag color={color} key={tag}>
    //         {tag}
    //       </Tag>
    //     );
    //   },
    // },
    {
      title: "STATUS",
      dataIndex: "status",
      render: (tag) => {
        let color = tag?.toLowerCase() === "inactive" ? "volcano" : "green";
        return (
          <Tag color={color} key={tag}>
            {tag}
          </Tag>
        );
      },
    },
    {
      title: "CREATED DATE",
      dataIndex: "createdAt",
    },
  ];
  const params = useParams();
  const dispatch = useDispatch();
  const [current, setCurrent] = useState(1);
  useEffect(() => {
    if (dateRange) {
      dispatch(
        getMerchantUsers(params.merchantId, current, dateRange[0], dateRange[1])
      );
    } else {
      dispatch(getMerchantUsers(params.merchantId, current));
    }
  }, [dispatch, params.merchantId, current, dateRange]);
  const changePage = async (page) => {
    setCurrent(page);
    dispatch(getMerchantUsers(params.merchantId, page));
  };
  const usersStatus = useSelector((state) => state.merchant.usersStatus);
  const usersPagination = useSelector(
    (state) => state.merchant.usersPagination
  );

  const users = useSelector((state) => state.merchant.users);
  const userData = users.map((i) => {
    return {
      key: i.id,
      name: i.full_name,
      email: i.email,
      phone: i.phone,
      verified: i.verified,
      status: i.status,
      createdAt: new Date(i.created_at).toLocaleDateString(),
    };
  });

  //sorting
  let sortedUsers = userData;

  sortedUsers = _.orderBy(userData, ["name"], [sortBy]);
  let filteredUsers = sortedUsers;

  if (filterProp && filterValue) {
    filteredUsers = _.filter(sortedUsers, function (o) {
      return o[filterProp].toLowerCase() === filterValue.toLowerCase();
    });
  }
  const searcher = new FuzzySearch(filteredUsers, ["name", "email", "phone"]);

  let searchResult = filteredUsers;
  if (searchQuery) {
    searchResult = searcher.search(searchQuery);
  }
  //sorting

  useEffect(() => {
    if (usersStatus === "failed") {
      message.error("Could not load merchant users. Try Reload!");
    }
  }, [usersStatus]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;
  return (
    <div>
      <div style={{ marginBottom: 16 }}>
        <span style={{ marginLeft: 8 }}>
          {hasSelected ? `Selected ${selectedRowKeys.length} items` : ""}
        </span>
      </div>
      <Table
        scroll={{ x: true }}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={searchResult}
        pagination={{
          current,
          defaultCurrent: usersPagination?.current_page,
          total: usersPagination?.total,
          onChange: (page, pageSize) => changePage(page),
          pageSize: Number(usersPagination?.per_page),
          showSizeChanger: false,
        }}
      />
    </div>
  );
}
