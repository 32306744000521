/** @format */

import { Col, Row, Form, Input, Divider, Upload, message, Spin } from "antd";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import "./style.css";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeAuthInput,
  changePassword,
  getLoggedInUser,
  logUserOut,
} from "../../store/authSlice";
import { changeDashboardValue, updateUser } from "../../store/dashboardSlice";
import ImgCrop from "antd-img-crop";

export default function ProfileScreen() {
  const [form1, form2] = Form.useForm();
  const dispatch = useDispatch();
  const resetError = useSelector((state) => state.user.resetError);
  const resetLoading = useSelector((state) => state.user.resetLoading);
  const updateUserStatus = useSelector(
    (state) => state.dashboard.updateUserStatus
  );
  const userupdateLoading = useSelector(
    (state) => state.dashboard.userupdateLoading
  );

  const userupdateError = useSelector(
    (state) => state.dashboard.userupdateError
  );
  const resetStatus = useSelector((state) => state.user.resetStatus);
  const user = getLoggedInUser();

  useEffect(() => {
    if (resetStatus === "success") {
      message.success("Password Update");
      dispatch(logUserOut());
    }
    if (resetStatus === "failed") {
      message.error(resetError || "Error Updating Password");
    }
    if (updateUserStatus === "success") {
      message.success("Profile updated successfully!");
    }
    if (updateUserStatus === "failed") {
      if (userupdateError) {
        message.error(userupdateError);
      } else {
        message.error("Could not update profile, Try again!");
      }
    }
    dispatch(changeDashboardValue("updateUserStatus", "pending"));
    dispatch(changeAuthInput("resetStatus", "pending"));
  }, [resetStatus, dispatch, resetError, updateUserStatus, userupdateError]);

  const [imageUrl, setImageUrl] = useState(user?.profile_image);
  const [s3Url, setS3Url] = useState(process.env.REACT_APP_IMAGE);

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }
  const handleChange = (info) => {
    setS3Url("");
    getBase64(info.file.originFileObj, (imageUrl) => {
      setImageUrl(imageUrl);

    });
  };
  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  return (
    <div>
      <div className='large-header pt-30 mt-20 '>
        Profile
        <Spin spinning={userupdateLoading} />
      </div>
      <div className='primary-header'>
        Here are the lastest report on your account
      </div>
      <Form
        form={form1}
        layout='vertical'
        initialValues={{
          full_name: user?.full_name,
          email: user?.email,
          profile_image: user?.profile_image,
          role_id: user?.role_id,
          username: user?.username,
          phone_number: user?.phone_number,
        }}
        size='large'
        onFinish={(values) => {
          const noChanges =
            values.full_name === user?.full_name &&
            values.phone_number === user?.phone_number &&
            values.email === user?.email &&
            values.profile_image === imageUrl;
          if (noChanges) {
            message.error("No changes made");
          } else {
            const data = {
              ...values,
              role_id: user?.role_id,
              username: user?.username,
              profile_image: imageUrl,
            };

            dispatch(updateUser(user.uuid, data));
          }
        }}>
        <Row
          gutter={{ xs: 8, sm: 16, md: 24, lg: 64 }}
          style={{ marginBottom: 20 }}
          className='pt-30 mt-20'>
          <Col xs={24} sm={24} md={24} lg={12}>
            <div className='medium-header  '>Change Profile Picture</div>
            <div className='primary-header'>
              Choose a new avatar to be used across primhex
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12}>
            <Form.Item required name='profile_image'>
              <ImgCrop rotate>
                <Upload
                  name='profile_image'
                  listType='picture-card'
                  className='avatar-uploader'
                  style={{ borderWidth: 0, width: 0, height: 0 }}
                  showUploadList={false}
                  onPreview={onPreview}
                  onChange={handleChange}>
                  <div
                    className='pic-avatar2'
                    style={{ backgroundImage: `url(${s3Url + imageUrl})` }}>
                    <FontAwesomeIcon icon={faPen} color='white' />
                  </div>
                </Upload>
              </ImgCrop>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 64 }} className=''>
          <Col xs={24} sm={24} md={24} lg={12}>
            <div className='medium-header  '>Full Name</div>
            <div className='primary-header'>Customize your account name</div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12}>
            <Form.Item name='full_name'>
              <Input placeholder='Full name' size='large' />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 64 }} className='pt-30 mt-20'>
          <Col xs={24} sm={24} md={24} lg={12}>
            <div className='medium-header  '>Phone Number</div>
            <div className='primary-header'>Update your phone number</div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12}>
            <Form.Item
              name='phone_number'
              rules={[{ min: 11, message: "Phone number is invalid" }]}>
              <Input placeholder='Phone Number' size='large' type='tel' />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 64 }} className='pt-30 mt-20'>
          <Col xs={24} sm={24} md={24} lg={12}>
            <div className='medium-header  '>Email Address</div>
            <div className='primary-header'>Update your email address</div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12}>
            <Form.Item name='email'>
              <Input disabled type='email' placeholder='user@gmail.com' size='large' />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item className='my-3'>
          <button type='submit' style={styles.button}>
            Update Profile
          </button>
        </Form.Item>
      </Form>

      <div className='large-header pt-30 mt-20 '>
        Change Password <Spin spinning={resetLoading} />{" "}
      </div>
      <Divider />
      <Form
        form={form2}
        layout='vertical'
        initialValues={{}}
        size='large'
        onFinish={(values) => {
          dispatch(changePassword(values));
        }}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 64 }} className='pt-30 mt-20'>
          <Col xs={24} sm={24} md={24} lg={12}>
            <div className='medium-header  '>Old Password</div>
            <div className='primary-header'>Fill in your old password</div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Old Password is required",
                },
              ]}
              name='current_password'>
              <Input type='password' placeholder='Old Password' size='large' />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 64 }} className='pt-30 mt-20'>
          <Col xs={24} sm={24} md={24} lg={12}>
            <div className='medium-header  '>New Password</div>
            <div className='primary-header'>Fill in your new password</div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "New Password",
                },
              ]}
              name='new_password'>
              <Input type='password' placeholder='New Password' size='large' />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 64 }} className='pt-30 mt-20'>
          <Col xs={24} sm={24} md={24} lg={12}>
            <div className='medium-header  '>Confirm New Password</div>
            <div className='primary-header'>Confirm your new password</div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12}>
            <Form.Item
              dependencies={["new_password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("new_password") === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      new Error(
                        "The two passwords that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
              name='confirm_new_password'>
              <Input
                type='password'
                placeholder='Confirm New Password'
                size='large'
              />
            </Form.Item>
          </Col>
        </Row>
        <button style={styles.button}>Update Password</button>
      </Form>
    </div>
  );
}
export const styles = {
  button: {
    marginTop: 20,
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 16,
    lineHeight: 22,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: 232,
    height: 45,
    borderWidth: 0,
    background: "#404C96",
    borderRadius: 3,
    color: "#FFFFFF",
  },
};
