/** @format */

import React, { useEffect } from "react";
import prihmex_logo from "../../assets/prihmex-logo.svg";
import question_mark from "../../assets/bx_bxs-help-circle.png";
import "./style.css";
import { Form, Input, Button, Spin, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  changeAuthInput,
  getLoggedInUser,
  logUserIn,
} from "../../store/authSlice";
import { Redirect } from "react-router-dom";
import { useState } from "react";

export default function Login() {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [user, setUser] = useState(null);
  const status = useSelector((state) => state.user.status);
  const error = useSelector((state) => state.user.error);
  const loading = useSelector((state) => state.user.loading);
  useEffect(() => {
    const user = getLoggedInUser();
    setUser(user);
    dispatch(changeAuthInput("error", ""));
  }, [dispatch]);
  useEffect(() => {
    if (status === "failed") {
      if (error) {
        message.error(error);
      } else {
        message.error("Error logging in, try again!");
      }
    }
  }, [status, error, dispatch]);
  if (user) return <Redirect to='/company-admin' />;
  return (
    <div id='loginScrreen'>
      <div className='image-section '>
        <div className='overlay-blue d-flex justify-content-center align-items-center'>
          <img src={prihmex_logo} alt='' />
        </div>
      </div>
      <div className='text-section'>
        <h1>Welcome Back!</h1> <Spin spinning={loading} />
        <p className='mb-5'>
          Kindly fill in your credentials to login into your account.{" "}
        </p>
        <Form
          form={form}
          layout='vertical'
          initialValues={{
            username: "",
            password: "",
          }}
          size='large'
          onFinish={(values) => {
            dispatch(logUserIn(values.username, values.password));
          }}>
          <Form.Item
            label='Username'
            required
            name='username'
            rules={[
              {
                required: true,
                message: "Username is required!",
              },
            ]}
            className='my-2'>
            <Input placeholder='Username' />
          </Form.Item>
          <Form.Item
            label='Your Password'
            name='password'
            required
            rules={[
              {
                required: true,
                message: "Password is required!",
              },
            ]}
            className='my-2'>
            <Input.Password placeholder='Password' />
          </Form.Item>
          <Form.Item className='my-3'>
            <Button
              type='primary'
              color='#404c96'
              style={{ backgroundColor: "#404c96" }}
              htmlType='submit'>
              Login into Account
            </Button>
          </Form.Item>
        </Form>
        <hr />
        <div className='d-flex align-items-start'>
          <div className='blue-box '>
            <img src={question_mark} alt='' />
          </div>
          <p className=''>
            Experiencing any problem with loging into your account ? Kindly
            contact the super admin to help resolve your issues.
          </p>
        </div>
      </div>
    </div>
  );
}
