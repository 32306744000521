/** @format */

import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, message, Spin } from "antd";
import React from "react";
import "./style.css";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { getMerchant, getMerchantStats } from "../../store/merchantSlice";
import { useDispatch, useSelector } from "react-redux";
import MerchantTransactionsTable from "../../components/MerchantTransactionsTable";
import { changeDashboardValue } from "../../store/dashboardSlice";

export default function MerchantOverview({ visible = false, setCurrent }) {
  const params = useParams();
  const dispatch = useDispatch();

  const merchantStatsStatus = useSelector(
    (state) => state.merchant.merchantStatsStatus
  );
  const merchantStatsError = useSelector(
    (state) => state.merchant.merchantStatsError
  );
  const merchantStats = useSelector((state) => state.merchant.merchantStats);
  const merchant = useSelector((state) => state.merchant.merchant);
  const merchantLoading = useSelector(
    (state) => state.merchant.merchantLoading
  );

  const merchantError = useSelector((state) => state.merchant.merchantError);
  const merchantStatus = useSelector((state) => state.merchant.merchantStatus);
  const usersLoading = useSelector((state) => state.merchant.usersLoading);
  const usersStatus = useSelector((state) => state.merchant.usersStatus);
  useEffect(() => {
    dispatch(getMerchantStats(params.merchantId));
    dispatch(getMerchant(params.merchantId));
  }, [dispatch, params.merchantId]);
  useEffect(() => {
    if (merchantStatus === "failed") {
      if (merchantError) {
        message.error(merchantError);
      } else {
        message.error("Could not load merchant details. Try Reload!");
      }
    }
    if (usersStatus === "failed") {
      message.error("Could not load merchant users. Try Reload!");
    }
    if (merchantStatsStatus === "failed") {
      if (merchantStatsError) {
        message.error(merchantStatsError);
      } else {
        message.error("Could not load merchant stats. Try Reload!");
      }
    }
    dispatch(changeDashboardValue("merchantError", ""));
  }, [
    merchantStatus,
    usersStatus,
    merchantError,
    dispatch,
    merchantStatsError,
    merchantStatsStatus,
  ]);
  if (!visible) return null;
  return (
    <div id='overview'>
      <div className='large-header secondary pt-30 mt-20'>
        {merchant.business_name} <Spin spinning={merchantLoading} />{" "}
      </div>
      <div className='primary-header'>
        Here is the latest report on {merchant.business_name}
      </div>
      <div className='overview-items'>
        <div className='overview-item overview-item-one'>
          <div className='overview-item-text'>
            <div className='small-header secondary'>Total Revenue</div>
            <div className='small-header '>₦{merchantStats.total_revenue}</div>
            <div className='primary-p text-light mt-10'>
              {" "}
              across all primhex products
            </div>
          </div>
          <div className='overview-item-image'>
            <div className='overview-item-avatar'>
            ₦
            </div>
            <div className='overview-item-circle'></div>
          </div>
        </div>
        <div className='overview-item overview-item-two'>
          <div className='overview-item-text'>
            <div className='small-header secondary'>Users</div>
            <div className='small-header '>{merchantStats.total_users}</div>
            <div className='primary-p text-light mt-10'>
              {" "}
              across all primhex products
            </div>
          </div>
          <div className='overview-item-image'>
            <div className='overview-item-avatar'>
              <FontAwesomeIcon icon={faUsers} color='#9B83DE' />
            </div>
            <div className='overview-item-circle'></div>
          </div>
        </div>
        <div className='overview-item overview-item-three'>
          <div className='overview-item-text'>
            <div className='small-header secondary'>Transactions</div>
            <div className='small-header '>
              {merchantStats.total_transactions}
            </div>
            <div className='primary-p text-light mt-10'>
              {" "}
              across all primhex products
            </div>
          </div>
          <div className='overview-item-image'>
            <div className='overview-item-avatar'>
            ₦
            </div>
            <div className='overview-item-circle'></div>
          </div>
        </div>
        <div className='overview-item overview-item-one'>
          <div className='overview-item-text'>
            <div className='small-header secondary'>Riders</div>
            <div className='small-header '>{merchantStats.total_riders}</div>
            <div className='primary-p text-light mt-10'>
              {" "}
              across all primhex products
            </div>
          </div>
          <div className='overview-item-image'>
            <div className='overview-item-avatar'>
            ₦
            </div>
            <div className='overview-item-circle'></div>
          </div>
        </div>
      </div>

      <div className='bg-white mt-20 br-10' style={{ padding: 16 }}>
        <div
          //   style={{ margin: 16, marginLeft: 0 }}
          className='d-flex justify-content-between mb-20 mt-20'>
          <div className='large-header secondary '>
            Transactions <Spin spinning={usersLoading} />
          </div>
          <Button
            className='bg-green text-white br-10'
            type='text'
            onClick={() => setCurrent("three")}>
            View All
          </Button>
        </div>
        <MerchantTransactionsTable />
      </div>
    </div>
  );
}
