/** @format */

import React, { useState } from "react";
import NavHeader from "../../components/NavHeader/NavHeader";
import "./style.css";
import { Input, message, Spin } from "antd";
import { Row, Col } from "antd";
import MerchantsTable from "../../components/MerchantsTable";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import MerchantScreen from "../MerchantScreen/MerchantScreen";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { changeDashboardValue } from "../../store/dashboardSlice";

const { Search } = Input;

export default function MerchantsScreen() {
  const dispatch = useDispatch();
  const merchantsLoading = useSelector(
    (state) => state.dashboard.merchantsLoading
  );
  const loadMerchantsStatus = useSelector(
    (state) => state.dashboard.loadMerchantsStatus
  );
  const merchantsError = useSelector((state) => state.dashboard.merchantsError);
  let { path } = useRouteMatch();
  const [searchQuery, setSearchQuery] = useState("");
  useEffect(() => {
    if (loadMerchantsStatus === "failed") {
      if (merchantsError) {
        message.error(merchantsError);
      } else {
        message.error("Could not load merchants. Try Again!");
      }
    }
    dispatch(changeDashboardValue("merchantsError", ""));
  }, [loadMerchantsStatus, merchantsError, dispatch]);

  return (
    <div>
      <Switch>
        <Route path={`${path}/:merchantId`}>
          <MerchantScreen />
        </Route>
        <Route exact path={path}>
          <NavHeader />

          <div className='large-header secondary pt-30'>
            Merchants <Spin spinning={merchantsLoading} />{" "}
          </div>
          <div className='primary-header'>
            Here are the merchants registered on Primhex
          </div>
          <Row gutter={16} style={{ marginTop: 20 }}>
            <Col
              className='gutter-row'
              lg={{ span: 6 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}>
              <Search
                placeholder='input search text'
                enterButton
                color='green'
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                }}
              />
            </Col>
          </Row>
          <MerchantsTable searchQuery={searchQuery} />
        </Route>
      </Switch>
    </div>
  );
}
