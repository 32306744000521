/** @format */

import React, { useEffect, useState } from "react";
import { Button, message, Spin, Table } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faPen,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { Input, Modal, Form } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  changeDashboardValue,
  loadSubscriptionFeatures,
  loadSubscriptions,
  deleteSubscriptionPlan,
  updateSubscriptionPlan,
} from "../store/dashboardSlice";
import _ from "lodash";
import FuzzySearch from "fuzzy-search";

const FeaturesTable = ({
  sortBy,
  filterProp,
  filterValue,
  searchQuery,
}) => {
  const dispatch = useDispatch();

  const subscriptionsPagination = useSelector(
    (state) => state.dashboard.subscriptionsPagination
  );
  const features = useSelector((state) => state.dashboard.subscriptions);
  const subscriptionsAdding = useSelector(
    (state) => state.dashboard.subscriptionsAdding
  );

  const subscriptionPlanUpdating = useSelector(
    (state) => state.dashboard.subscriptionPlanUpdating
  );
  const updateSubscriptionPlanStatus = useSelector(
    (state) => state.dashboard.updateSubscriptionPlanStatus
  );
  const subscriptionDeleteLoading = useSelector(
    (state) => state.dashboard.subscriptionDeleteLoading
  );
  const deleteSubscriptionPlanStatus = useSelector(
    (state) => state.dashboard.deleteSubscriptionPlanStatus
  );
  const loadsubscriptionFeaturesStatus = useSelector(
    (state) => state.dashboard.loadsubscriptionFeaturesStatus
  );
  const subscriptionFeaturesError = useSelector(
    (state) => state.dashboard.subscriptionFeaturesError
  );
  const updateSubscriptionPlanError = useSelector(
    (state) => state.dashboard.updateSubscriptionPlanError
  );
  const deleteSubscriptionPlanError = useSelector(
    (state) => state.dashboard.deleteSubscriptionPlanError
  );

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isEditUserModalVisible, setIsEditUserModalVisible] = useState(false);
  const [okModal2, setOkModal2] = React.useState(false);
  const [okModal, setOkModal] = React.useState(false);
  const [subscription, setDescription] = useState(null);
  const [current, setCurrent] = useState(1);

  useEffect(() => {
    dispatch(loadSubscriptionFeatures());
    dispatch(loadSubscriptions(current));
  }, [dispatch, current]);
  useEffect(() => {
    if (updateSubscriptionPlanStatus === "success") {
      setIsEditUserModalVisible(false);
      setOkModal(true);
    }
    if (updateSubscriptionPlanStatus === "failed") {
      if (updateSubscriptionPlanError) {
        message.error(updateSubscriptionPlanError);
      } else {
        message.error("Could not update plan, Try again!");
      }
    }
    dispatch(changeDashboardValue("updateSubscriptionPlanStatus", "pending"));

    if (deleteSubscriptionPlanStatus === "failed") {
      if (deleteSubscriptionPlanError) {
        message.error(deleteSubscriptionPlanError);
      } else {
        message.error("Could not delete plan, Try again!");
      }
    }
    
    if (deleteSubscriptionPlanStatus === "success") {
      setOkModal2(true);
    }
    dispatch(changeDashboardValue("deleteSubscriptionPlanStatus", "pending"));
    dispatch(changeDashboardValue("updateSubscriptionPlanError", ""));
    dispatch(changeDashboardValue("deleteSubscriptionPlanError", ""));
    dispatch(changeDashboardValue("subscriptionFeaturesError", ""));
  }, [
    updateSubscriptionPlanStatus,
    deleteSubscriptionPlanStatus,
    dispatch,
    loadsubscriptionFeaturesStatus,
    subscriptionFeaturesError,
    updateSubscriptionPlanError,
    deleteSubscriptionPlanError,
  ]);

  const subscriptionData = features.map((i) => {
    return {
      ...i,
      key: i.uuid,
      edit: i,
      delete: i,
    };
  });

  let sortedSubscriptions = subscriptionData;

  sortedSubscriptions = _.orderBy(subscriptionData, ["name"], [sortBy]);
  let filteredUsers = sortedSubscriptions;

  if (filterProp && filterValue) {
    filteredUsers = _.filter(sortedSubscriptions, [filterProp, filterValue]);
  }
  const searcher = new FuzzySearch(filteredUsers, [
    "username",
    "name",

  ]);

  let searchResult = filteredUsers;
  if (searchQuery) {
    searchResult = searcher.search(searchQuery);
  }

  const columns = [
    {
      title: "NAME",
      dataIndex: "name",
    },
    

    {
      title: () => <Spin spinning={subscriptionPlanUpdating} />,
      dataIndex: "edit",
      render: (value) => {
        return (
          <FontAwesomeIcon
            icon={faPen}
            color='#9E9E9E'
            className='cursor'
            onClick={() => {
              setDescription(value);
              setIsEditUserModalVisible(true);
            }}
          />
        );
      },
    },
    {
      title: () => <Spin spinning={subscriptionDeleteLoading} />,
      dataIndex: "delete",
      render: (value) => {
        return (
          <FontAwesomeIcon
            icon={faTrash}
            color='#F95050'
            className='cursor'
            onClick={() => {
              // setUser(value);
              // setIsEditUserModalVisible(true);
              dispatch(deleteSubscriptionPlan(value.uuid));
            }}
          />
        );
        // deleteUser
      },
    },
  ];

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  const changePage = async (page) => {
    setCurrent(page);
    dispatch(loadSubscriptions(page));
  };
  return (
    <div>
      <div style={{ marginBottom: 16 }}>
        <span style={{ marginLeft: 8 }}>
          {hasSelected ? `Selected ${selectedRowKeys.length} items` : ""}
        </span>
      </div>
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={searchResult}
        pagination={{
          current,
          defaultCurrent: subscriptionsPagination?.current_page,
          total: subscriptionsPagination?.total,
          onChange: (page, pageSize) => changePage(page),
          pageSize: Number(subscriptionsPagination?.per_page),
          showSizeChanger: false,
        }}
      />

      <Modal
        title='Edit plan'
        visible={isEditUserModalVisible}
        footer={null}
        onCancel={() => setIsEditUserModalVisible(false)}>
        <Form
          name='basic'
          labelCol={{ span: 8 }}
          wrapperCol
          initialValues={{ ...subscription }}
          onFinish={(values) => {
            let noChanges = true;

            const data = {};
            
            if (values.name !== subscription.name) {
              data.name = values.name;
              noChanges = false;
            }
            if (noChanges) {
              message.warning("No changes");
            } else {
              dispatch(
                updateSubscriptionPlan(subscription.uuid, {
                  ...subscription,
                  ...data,
                })
              );
            }
          }}
          onFinishFailed={(errorInfo) => {}}
          autoComplete='off'
          layout='vertical'>
          <Form.Item
            name='name'
            rules={[{ required: true, message: "Please input Plan Name!" }]}>
            <Input placeholder='Plan Name' />
          </Form.Item>
          <Form.Item
            name='amount'
            rules={[{ required: true, message: "Please input Plan Amount!" }]}>
            <Input placeholder='Plan Amount' />
          </Form.Item>

          <Form.Item
            name='duration'
            rules={[
              {
                required: true,
                message: "Please input Plan Duration!",
              },
            ]}>
            <Input placeholder='Plan Duration' />
          </Form.Item>
          <Form.Item
            name='description'
            rules={[
              {
                required: true,
                message: "Please input Plan Description!",
              },
            ]}>
            <Input placeholder='Plan Description' />
          </Form.Item>

          <Form.Item wrapperCol>
            <Button type='primary' htmlType='submit'>
              ADD
            </Button>
            <Spin spinning={subscriptionsAdding} />
          </Form.Item>
        </Form>
      </Modal>
      <Modal visible={okModal} footer={null} style={{ padding: 20 }}>
        <div className='jcc'>
          <FontAwesomeIcon icon={faCheckCircle} size='10x' color='#94FF92' />
          <h1>Plan was edited successfully!</h1>

          <Button
            type='ghost'
            style={{ backgroundColor: "#6BAF43", color: "white" }}
            size='large'
            className='br-10'
            onClick={() => setOkModal(false)}>
            CLOSE
          </Button>
        </div>
      </Modal>
      <Modal visible={okModal2} footer={null} style={{ padding: 20 }}>
        <div className='jcc'>
          <FontAwesomeIcon icon={faCheckCircle} size='10x' color='#E9515B' />
          <h1>Plan was deleted successfully!</h1>

          <Button
            type='ghost'
            style={{ backgroundColor: "#6BAF43", color: "white" }}
            size='large'
            className='br-10'
            onClick={() => setOkModal2(false)}>
            CLOSE
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default FeaturesTable;
