/** @format */

import React, { useEffect, useState } from "react";
import "./style.css";
import { Button, Input, message } from "antd";
import { Row, Col } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import RolesListTable from "../../components/RolesListTable";
import { Link, Route, Switch, useRouteMatch } from "react-router-dom";
import UpdateRoleScreen from "../UpdateRoleScreen/UpdateRoleScreen";
import AddNewRoleScreen from "../AddNewRoleScreen/AddNewRoleScreen";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { changeDashboardValue } from "../../store/dashboardSlice";

const { Search } = Input;

export default function RolesList({ visible = false }) {
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState("");
  const [role, setRole] = useState(null);
  const deleteRoleStatus = useSelector(
    (state) => state.dashboard.deleteRoleStatus
  );
  const deleteRoleError = useSelector(
    (state) => state.dashboard.deleteRoleError
  );

  useEffect(() => {
    if (deleteRoleStatus === "success") {
      message.success("Role Deleted successfully!");
    }
    if (deleteRoleStatus === "failed") {
      if (deleteRoleError) {
        message.error(deleteRoleError);
      } else {
        message.error("Could not delete role, Try again!");
      }
    }
    dispatch(changeDashboardValue("deleteRoleError", ""));
  }, [deleteRoleStatus, deleteRoleError, dispatch]);
  let { path, url } = useRouteMatch();

  if (!visible) return null;
  return (
    <Switch>
      <Route path={`${path}/add-new`}>
        <AddNewRoleScreen />
      </Route>
      <Route path={`${path}/:roleId`}>
        <UpdateRoleScreen role={role} />
      </Route>
      <Route path={`${path}`}>
        <div>
          <div className='large-header secondary pt-30'>Roles</div>
          <div className='primary-header'>
            Here are the list of roles available{" "}
            {/* <Spin spinning={rolesLoading} /> */}
          </div>
          <Row
            gutter={16}
            style={{ marginTop: 20, justifyContent: "space-between" }}>
            <Col className='gutter-row' span={6}>
              <Search
                placeholder='input search text'
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                }}
                enterButton
                color='green'
                style={{ backgroundColor: "#5322DB" }}
              />
            </Col>
            <Col className='gutter-row'>
              <Link to={`${url}/add-new`}>
                <Button
                  type='text'
                  style={{ backgroundColor: "#131573", color: "#ffffff" }}
                  className='text-white br-5'>
                  <FontAwesomeIcon icon={faPlus} className='mr-10 text-white' />
                  Add New Role{" "}
                </Button>
              </Link>
            </Col>
          </Row>
          <RolesListTable
            searchQuery={searchQuery}
            role={role}
            setRole={setRole}
          />
        </div>
      </Route>
    </Switch>
  );
}
