/** @format */

import React, { useEffect } from "react";
import { Row, Col, Button, Space, Spin, message } from "antd";
import { Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { changeDashboardValue, updateRole } from "../../store/dashboardSlice";
import { useHistory } from "react-router-dom";

export default function UpdateRoleScreen({ role }) {
  const dispatch = useDispatch();
  const { goBack } = useHistory();
  const updateRoleStatus = useSelector(
    (state) => state.dashboard.updateRoleStatus
  );
  const updateRoleError = useSelector(
    (state) => state.dashboard.updateRoleError
  );
  const rolesLoading = useSelector((state) => state.dashboard.rolesLoading);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  useEffect(() => {
    if (updateRoleStatus === "success") {
      message.success("Role updated successfully!");
      goBack();
    }
    if (updateRoleStatus === "failed") {
      if (updateRoleError) {
        message.error(updateRoleError);
      } else {
        message.error("Could not update role, Try again!");
      }
    }
    dispatch(changeDashboardValue("updateRoleError", ""));
    dispatch(changeDashboardValue("updateRoleStatus", "pending"));
  }, [updateRoleStatus, dispatch, goBack, updateRoleError]);

  return (
    <div>
      <div className='d-flex justify-content-between align-items-end'>
        <div className='large-header secondary pt-30 mt-20 '>Manage Role</div>
        <Space wrap>
          <Button
            className='text-white br-10'
            type='text'
            style={{ backgroundColor: "#5322DB" }}>
            Reset Role
          </Button>
          <Button
            className='text-white br-10'
            type='text'
            style={{ backgroundColor: "#131573" }}
            onClick={() => {
              if (name || description) {
                const data = {};
                if (name) {
                  data.name = name;
                }
                if (description) {
                  data.description = description;
                }
                dispatch(
                  updateRole(role.uuid, {
                    ...role,
                    ...data,
                  })
                );
              } else {
                message.warning("No changes made!");
              }
            }}>
            Save Changes
          </Button>
        </Space>
      </div>
      <div className='primary-header mb-30'>
        Define the rights given to the role <Spin spinning={rolesLoading} />
      </div>

      <div className='w-100 bg-white p-20 mt-30 br-10 py-30'>
        <div className='large-header secondary '>{role?.name}</div>

        <div className='primary-header mb-30'>{role?.description}</div>
        <Row gutter={32}>
          <Col className='gutter-row' span={12}>
            <Input
              placeholder='Admin Name'
              size='large'
              defaultValue={role?.name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </Col>
          <Col className='gutter-row' span={12}>
            <Input
              placeholder='Admin description'
              defaultValue={role?.description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
              size='large'
            />
          </Col>
        </Row>
      </div>
      <div className='w-100 bg-white p-20 mt-30 br-10 py-30 min-h-250'></div>
    </div>
  );
}
