import { combineReducers } from "redux";
import userReducer from "./authSlice";
import merchantReducer from "./merchantSlice";
import dashboardReducer from "./dashboardSlice";

export default combineReducers({
  user: userReducer,
  dashboard: dashboardReducer,
  merchant: merchantReducer,
});
