/** @format */

import React, { useEffect } from "react";
import { Row, Col, Button, Space, Spin, message, Form } from "antd";
import { Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { addRole, changeDashboardValue } from "../../store/dashboardSlice";
import { useHistory } from "react-router-dom";

export default function AddNewRoleScreen() {
  const dispatch = useDispatch();
  const { goBack } = useHistory();
  const rolesLoading = useSelector((state) => state.dashboard.rolesLoading);
  const addRoleError = useSelector((state) => state.dashboard.addRoleError);
  const addRoleStatus = useSelector((state) => state.dashboard.addRoleStatus);
  useEffect(() => {
    dispatch(changeDashboardValue("addRoleStatus", "pending"));
  }, [dispatch]);
  useEffect(() => {
    if (addRoleStatus === "success") {
      message.success("Role Added successfully!");
      goBack();
    }
    if (addRoleStatus === "failed") {
      if (addRoleError) {
        message.error(addRoleError);
      } else {
        message.error("Could not add role, Try again!");
      }
    }
    dispatch(changeDashboardValue("addRoleStatus", "pending"));
    dispatch(changeDashboardValue("addRoleError", ""));
  }, [addRoleStatus, goBack, dispatch, addRoleError]);
  return (
    <div>
      <Form
        name='basic'
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{}}
        onFinish={(values) => {
          dispatch(addRole(values));
        }}
        onFinishFailed={(errorInfo) => {}}
        autoComplete='off'>
        <div className='d-flex justify-content-between align-items-end'>
          <div className='large-header secondary pt-30 mt-20 '>Create Role</div>
          <Space wrap>
            <Button
              className='text-white br-10'
              type='text'
              style={{ backgroundColor: "#5322DB" }}>
              Reset Role
            </Button>

            <Form.Item style={{ marginBottom: 0 }}>
              <Button
                className='text-white br-10'
                type='primary'
                htmlType='submit'
                style={{ backgroundColor: "#131573" }}>
                Save Changes
              </Button>
            </Form.Item>
          </Space>
        </div>
        <div className='primary-header mb-30'>
          Define the rights given to the role <Spin spinning={rolesLoading} />
        </div>

        <div className='w-100 bg-white p-20 mt-30 br-10 py-30'>
          <div className='large-header secondary '>Details</div>

          <div className='primary-header mb-30'>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit,
          </div>
          <Row gutter={32}>
            <Col className='gutter-row' span={12}>
              <Form.Item
                wrapperCol
                rules={[{ required: true, message: "Please input role name!" }]}
                name='name'>
                <Input placeholder='Role' size='large' />
              </Form.Item>
            </Col>
            <Col className='gutter-row' span={12}>
              <Form.Item
                wrapperCol
                rules={[
                  { required: true, message: "Please input role description!" },
                ]}
                name='description'>
                <Input placeholder='Description' size='large' />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div className='w-100 bg-white p-20 mt-30 br-10 py-30 min-h-250'></div>
      </Form>
    </div>
  );
}
