/** @format */

import React, { useEffect, useState } from "react";
import { Table, Tag } from "antd";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import FuzzySearch from "fuzzy-search";
import { getMerchantTransactions } from "../store/merchantSlice";
import { useParams } from "react-router-dom";
import { currencyFormatter } from "../assets/js/nFormatter";

const MerchantTransactionsTable = ({
  sortBy,
  filterProp,
  filterValue,
  searchQuery,
  dateRange,
}) => {
  const params = useParams();
  const [current, setCurrent] = useState(1);

  const dispatch = useDispatch();
  useEffect(() => {
    if (dateRange) {
      dispatch(
        getMerchantTransactions(
          params.merchantId,
          current,
          dateRange[0],
          dateRange[1]
        )
      );
    } else {
      dispatch(getMerchantTransactions(params.merchantId, current));
    }
  }, [dispatch, dateRange, params.merchantId, current]);

  const changePage = async (page) => {
    setCurrent(page);
    dispatch(getMerchantTransactions(params.merchantId, page));
  };

  const columns = [
    {
      title: "REF.ID",
      dataIndex: "ref",
    },
    {
      title: "DETAILS",
      dataIndex: "details",
    },
    {
      title: "CHANNEL",
      dataIndex: "channel",
    },
    {
      title: "AMOUNT",
      dataIndex: "amount",
      render: (tag) => {
        return <span>{currencyFormatter.format(tag)}</span>;
      },
    },
    {
      title: "TRANS.STATUS",
      dataIndex: "status",
      render: (tag) => {
        let color = tag.toLowerCase() === "inactive" ? "volcano" : "green";
        let text = tag.toLowerCase() === "inactive" ? "Failed" : "Success";

        return (
          <Tag color={color} key={tag} className='capitalize'>
            {text}
          </Tag>
        );
      },
    },
    {
      title: "DATE",
      dataIndex: "createdAt",
    },
  ];

  const transactionsPagination = useSelector(
    (state) => state.merchant.transactionsPagination
  );
  const transactions = useSelector((state) => state.merchant.transactions);
  const transactionsData = transactions.map((i, index) => {
    return {
      key: index,
      ref: i.reference_id,
      details: i.details,
      channel: i.channel,
      amount: i.amount,
      status: i.status,
      createdAt: new Date(i.created_at).toLocaleDateString(),
    };
  });
  //sorting
  let sortedTransaction = transactionsData;

  sortedTransaction = _.orderBy(transactionsData, ["ref"], [sortBy]);
  let filteredTransaction = sortedTransaction;

  if (filterProp && filterValue) {
    filteredTransaction = _.filter(sortedTransaction, function (o) {
      return o[filterProp].toLowerCase() === filterValue.toLowerCase();
    });
  }
  const searcher = new FuzzySearch(filteredTransaction, [
    "channel",
    "amount",
    "details",
    "ref",
  ]);

  let searchResult = filteredTransaction;
  if (searchQuery) {
    searchResult = searcher.search(searchQuery);
  }
  //sorting
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  return (
    <div>
      <div style={{ marginBottom: 16 }}>
        <span style={{ marginLeft: 8 }}>
          {hasSelected ? `Selected ${selectedRowKeys.length} items` : ""}
        </span>
      </div>
      <Table
        scroll={{ x: true }}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={searchResult}
        pagination={{
          current,
          defaultCurrent: transactionsPagination?.current_page,
          total: transactionsPagination?.total,
          onChange: (page, pageSize) => {
            changePage(page);
          },
          pageSize: Number(transactionsPagination?.per_page),
          showSizeChanger: false,
        }}
      />
    </div>
  );
};

export default MerchantTransactionsTable;
