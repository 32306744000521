/** @format */

import React, { useEffect, useState } from "react";
import NavHeader from "../../components/NavHeader/NavHeader";
import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMoneyBill,
  faThLarge,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { Menu, Dropdown, Button, Spin } from "antd";
import { DownOutlined } from "@ant-design/icons";
import UserTable from "../../components/UserTable";
import RevenueChart from "../../components/RevenueChart";
import { numberWithCommas } from "../../assets/js/nFormatter";
import { useDispatch, useSelector } from "react-redux";
import { getLoggedInUser } from "../../store/authSlice";
import { loadReport, loadStat } from "../../store/dashboardSlice";
import { NavLink } from "react-router-dom";

export default function OverView(props) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const years = [
    "2021",
    "2022",
    "2023",
    "2024",
    "2025",
    "2026",
    "2027",
    "2028",
    "2029",
    "2030",
    "2031",
    "2032",
  ];
  const dispatch = useDispatch();
  const [user, setUser] = useState(null);
  const [month, setMonth] = useState(new Date().getMonth());
  const [year, setYear] = useState(new Date().getFullYear().toString());

  useEffect(() => {
    const user = getLoggedInUser();
    setUser(user);
    dispatch(loadStat());
  }, [dispatch]);
  useEffect(() => {
    dispatch(loadReport(month + 1, year));
  }, [dispatch, month, year]);

  const {
    daily_report,
    monthly_report,
    yearly_report,
    graph_report,
  } = useSelector((state) => state.dashboard.report);

  const reportLoading = useSelector((state) => state.dashboard.reportLoading);
  const stat = useSelector((state) => state.dashboard.stat);
  return (
    <div id='overview'>
      <NavHeader />
      <div className='large-header secondary pt-30 mt-20'>
        Hi {user?.full_name}
      </div>
      <div className='primary-header'>Here is the latest report on Primhex</div>
      <div className='overview-items'>
        <div className='overview-item overview-item-one'>
          <div className='overview-item-text'>
            <div className='small-header secondary'>Total Revenue</div>
            <div className='small-header '>
              ₦{stat.total_revenue ? numberWithCommas(stat.total_revenue) : "0"}
            </div>
            <div className='primary-p text-light mt-10'>
              {" "}
              across all primhex products
            </div>
          </div>
          <div className='overview-item-image'>
            <div className='overview-item-avatar'>
            ₦
            </div>
            <div className='overview-item-circle'></div>
          </div>
        </div>
        <div className='overview-item overview-item-two'>
          <div className='overview-item-text'>
            <div className='small-header secondary'>Users</div>
            <div className='small-header '>{stat.total_users || "0"}</div>
            <div className='primary-p text-light mt-10'>
              {" "}
              across all primhex products
            </div>
          </div>
          <div className='overview-item-image'>
            <div className='overview-item-avatar'>
              <FontAwesomeIcon icon={faUsers} color='#9B83DE' />
            </div>
            <div className='overview-item-circle'></div>
          </div>
        </div>
        <div className='overview-item overview-item-three'>
          <div className='overview-item-text'>
            <div className='small-header secondary'>Merchants</div>
            <div className='small-header '>{stat.total_merchants || 0}</div>
            <div className='primary-p text-light mt-10'>
              {" "}
              across all primhex products
            </div>
          </div>
          <div className='overview-item-image'>
            <div className='overview-item-avatar'>
              <FontAwesomeIcon icon={faThLarge} color='#83B3DE' />
            </div>
            <div className='overview-item-circle'></div>
          </div>
        </div>
        <div className='overview-item overview-item-one'>
          <div className='overview-item-text'>
            <div className='small-header secondary'>Transactions</div>
            <div className='small-header '>
              {stat.total_transactions
                ? numberWithCommas(stat.total_transactions)
                : "0"}
            </div>
            <div className='primary-p text-light mt-10'>
              {" "}
              across all primhex products
            </div>
          </div>
          <div className='overview-item-image'>
            <div className='overview-item-avatar'>
              <FontAwesomeIcon icon={faMoneyBill} color='#83DE8C' />
            </div>
            <div className='overview-item-circle'></div>
          </div>
        </div>
      </div>
      <div className='business-revenue'>
        <div className='d-flex justify-content-between flex-wrap'>
          <div className='small-header secondary'>
            Business Revenue <Spin spinning={reportLoading} />{" "}
          </div>
          <div>
            <Dropdown
              className='ml-md-10 mt-3 mt-md-0 bg-light br-10'
              overlay={
                <Menu>
                  {years.map((y) => (
                    <Menu.Item key='1' onClick={() => setYear(y)}>
                      {y}{" "}
                    </Menu.Item>
                  ))}
                </Menu>
              }>
              <Button>
                {year} <DownOutlined />
              </Button>
            </Dropdown>
            <Dropdown
              className='ml-10 bg-light br-10'
              overlay={
                <Menu>
                  {months.map((m) => (
                    <Menu.Item
                      key='1'
                      onClick={() => setMonth(months.indexOf(m))}>
                      {m}
                    </Menu.Item>
                  ))}
                </Menu>
              }>
              <Button>
                {months[month]} <DownOutlined />
              </Button>
            </Dropdown>
          </div>{" "}
        </div>
        <div className='business-revenue-map'>
          <RevenueChart graph_report={graph_report} />
        </div>
        <div className='d-flex justify-content-between mt-20 flex-wrap'>
          <div className=''>
            <h1 className='small-header secondary'>Daily Report</h1>
            <p className='primary-header grey6 mt-20'>
              Total Revenue: ₦
              {daily_report?.total_revenue
                ? numberWithCommas(daily_report?.total_revenue)
                : "0"}
            </p>
            <p className='primary-header grey6 mt-20'>
              Total Transaction:{" "}
              {daily_report?.total_transactions
                ? numberWithCommas(daily_report?.total_transactions)
                : "0"}
            </p>
            <p className='primary-header grey6 mt-20'>
              Sucess Transaction:{" "}
              {daily_report?.successful_transactions
                ? numberWithCommas(daily_report?.successful_transactions)
                : "0"}
            </p>
            <p className='primary-header grey6 mt-20'>
              Failed Transaction:{" "}
              {daily_report?.failed_transactions
                ? numberWithCommas(daily_report?.failed_transactions)
                : "0"}
            </p>
            {/* <p className='primary-header grey6 mt-20'>Settlement: 40</p> */}
          </div>
          <div>
            <h1 className='small-header secondary'>Monthly Report</h1>
            <p className='primary-header grey6 mt-20'>
              Total Revenue: ₦
              {monthly_report?.total_revenue
                ? numberWithCommas(monthly_report?.total_revenue)
                : "0"}
            </p>
            <p className='primary-header grey6 mt-20'>
              Total Transaction:{" "}
              {monthly_report?.total_transactions
                ? numberWithCommas(monthly_report?.total_transactions)
                : "0"}
            </p>
            <p className='primary-header grey6 mt-20'>
              Sucess Transaction:{" "}
              {monthly_report?.successful_transactions
                ? numberWithCommas(monthly_report?.successful_transactions)
                : "0"}
            </p>
            <p className='primary-header grey6 mt-20'>
              Failed Transaction:{" "}
              {monthly_report?.failed_transactions
                ? numberWithCommas(monthly_report?.failed_transactions)
                : "0"}
            </p>
            {/* <p className='primary-header grey6 mt-20'>Settlement: 40</p> */}
          </div>
          <div>
            <h1 className='small-header secondary'>Yearly Report</h1>
            <p className='primary-header grey6 mt-20'>
              Total Revenue: ₦
              {yearly_report?.total_revenue
                ? numberWithCommas(yearly_report?.total_revenue)
                : "0"}
            </p>
            <p className='primary-header grey6 mt-20'>
              Total Transaction:{" "}
              {yearly_report?.total_transactions
                ? numberWithCommas(yearly_report?.total_transactions)
                : "0"}
            </p>
            <p className='primary-header grey6 mt-20'>
              Sucess Transaction:{" "}
              {yearly_report?.successful_transactions
                ? numberWithCommas(yearly_report?.successful_transactions)
                : "0"}
            </p>
            <p className='primary-header grey6 mt-20'>
              Failed Transaction:{" "}
              {yearly_report?.failed_transactions
                ? numberWithCommas(yearly_report?.failed_transactions)
                : "0"}
            </p>
            {/* <p className='primary-header grey6 mt-20'>Settlement: 40</p> */}
          </div>
        </div>
      </div>
      <div className='bg-white mt-20 br-10' style={{ padding: 16 }}>
        <div
          //   style={{ margin: 16, marginLeft: 0 }}
          className='d-flex justify-content-between mb-20 mt-20'>
          <div className='large-header secondary '>Users</div>
          <NavLink to='/company-admin/management'>
            <Button className='bg-green text-white br-10' type='text'>
              View All
            </Button>
          </NavLink>
        </div>
        <UserTable />
      </div>
    </div>
  );
}
