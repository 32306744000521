/** @format */

import React, { useEffect } from "react";
import prihmex_logo from "../../assets/prihmex-logo.svg";
import "./style.css";
import { Form, Input, Button, Spin, message, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { changeAuthInput, resetPassword } from "../../store/authSlice";
import { ReactComponent as Logo2 } from "../../assets/Logo2.svg";
import queryString from "query-string";

export default function ResetPassword(props) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  let params = queryString.parse(props.location.search);

  const resetError = useSelector((state) => state.user.resetError);
  const resetLoading = useSelector((state) => state.user.resetLoading);
  const resetStatus = useSelector((state) => state.user.resetStatus);
  const status = useSelector((state) => state.user.status);

  useEffect(() => {
    if (resetStatus === "success") {
      notification.success({
        message: "Password Successfully Updated",
        description: "Kindly provide us with correct information",
      });
    }
    if (resetStatus === "failed") {
      message.error(resetError || "Error Updating Password");
    }
    dispatch(changeAuthInput("resetStatus", "pending"));
    dispatch(changeAuthInput("resetError", ""));
  }, [resetStatus, dispatch, resetError]);

  useEffect(() => {
    if (status === "failed") {
      message.error("Error logging in, try again!");
    }
  }, [status]);
  return (
    <div id='loginScrreen'>
      <div className='image-section '>
        <div className='overlay-blue d-flex justify-content-center align-items-center'>
          <img src={prihmex_logo} alt='' />
        </div>
      </div>
      <div className='text-section'>
        <Logo2 style={{ marginBottom: 30 }} />

        <div>
          <h1>Update Password</h1> <Spin spinning={resetLoading} />
          <p className='mb-5'>Fill in your details to update your password</p>
          <Form
            form={form}
            layout='vertical'
            initialValues={{}}
            size='large'
            onFinish={(values) => {
              dispatch(
                resetPassword({
                  token: params.token,
                  email: params.email,
                  ...values,
                })
              );
            }}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Password",
                },
              ]}
              name='password'>
              <Input
                type='password'
                placeholder='Password'
                size='large'
                width
              />
            </Form.Item>
            <Form.Item
              dependencies={["password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      new Error(
                        "The two passwords that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
              name='password_confirmation'>
              <Input
                type='password'
                placeholder='Confirm Password'
                size='large'
                width
              />
            </Form.Item>

            <Form.Item className='my-3'>
              <Button
                type='primary'
                color='#404c96'
                style={{ backgroundColor: "#404c96", marginTop: 30 }}
                htmlType='submit'>
                Update Password
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
}
