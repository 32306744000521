/** @format */

import React, { Component } from "react";
import {
  GoogleMap,
  InfoWindow,
  Marker,
  useJsApiLoader,
  Polyline,
} from "@react-google-maps/api";

export class MapContainer extends Component {
  state = {
    showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: {},
  };

  // { isLoaded } = useJsApiLoader({
  //   id: 'google-map-script',
  //   googleMapsApiKey: "AIzaSyAdJa1SMy-qRh2a322QG3FtzMENUuJr5zM"
  // });

  onMarkerClick = (props, marker, e) =>
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
    });

  onMapClicked = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null,
      });
    }
  };



  render() {
    return (
      <div className='map-box'>
        <GoogleMap
          google={this.props.google}
          onClick={this.onMapClicked}
          className={"map"}
          zoom={14}
          style={{
            width: "100%",
            height: 442,
          }}
          initialCenter={{
            lat: Number(this.props.rider_coordinate?.rider_lat),
            lng: Number(this.props.rider_coordinate?.rider_long),
          }}
          containerStyle={{
            position: "relative",
            width: "100%",
            height: "100%",
          }}>
          <Marker
            name={"Driver's Location"}
            onClick={this.onMarkerClick}
            position={{
              lat: Number(this.props.rider_coordinate?.rider_lat),
              lng: Number(this.props.rider_coordinate?.rider_long),
            }}
            icon='http://maps.google.com/mapfiles/kml/pal2/icon39.png'
          />
          <Marker
            name={
              <div>
                Pick Up <br />
                {this.props.sender_info?.pickup_address}
              </div>
            }
            onClick={this.onMarkerClick}
            position={{
              lat: Number(this.props.sender_info?.pickup_lat),
              lng: Number(this.props.sender_info?.pickup_long),
            }}
            icon='http://maps.google.com/mapfiles/kml/paddle/P.png'
          />
          <Marker
            name={
              <div>
                Delivery Address <br />
                {this.props.receiver_info?.destination_address}
              </div>
            }
            onClick={this.onMarkerClick}
            icon='http://maps.google.com/mapfiles/kml/paddle/D.png'
            position={{
              lat: Number(this.props.receiver_info?.destination_lat),
              lng: Number(this.props.receiver_info?.destination_long),
            }}
          />

          <InfoWindow
            marker={this.state.activeMarker}
            visible={this.state.showingInfoWindow}>
            <div>
              <span>
                <strong>{this.state.selectedPlace.name}</strong>
              </span>
            </div>
          </InfoWindow>
          <Polyline
            path={[
              {
                lat: Number(this.props.receiver_info?.destination_lat),
                lng: Number(this.props.receiver_info?.destination_long),
              },
              {
                lat: Number(this.props.sender_info?.pickup_lat),
                lng: Number(this.props.sender_info?.pickup_long),
              },
            ]}
            strokeColor='blue'
            strokeOpacity={0.8}
            strokeWeight={2}
          />
              </GoogleMap>

      </div>
    );
  }
}

export default MapContainer;
