/** @format */

import React, { useEffect, useState } from "react";
import { message, Table, Tag } from "antd";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import FuzzySearch from "fuzzy-search";
import { changeMerchantValue, getMerchantRiders } from "../store/merchantSlice";
import { useParams } from "react-router-dom";

const columns = [
  {
    title: "FULL NAME",
    dataIndex: "name",
  },
  {
    title: "ADDRESS",
    dataIndex: "address",
  },
  {
    title: "PHONE NUMBER",
    dataIndex: "phone",
  },
  {
    title: "BIKE",
    dataIndex: "bike",
  },
  {
    title: "STATUS",
    dataIndex: "status",
    render: (tag) => {
      let color = tag.toLowerCase() === "inactive" ? "volcano" : "green";
      return (
        <Tag color={color} key={tag} className='capitalize'>
          {tag}
        </Tag>
      );
    },
  },
  {
    title: "CREATED DATE",
    dataIndex: "createdAt",
  },
];

export default function MerchantRidersTable({
  sortBy,
  filterProp,
  filterValue,
  searchQuery,
  // dateRange,
}) {
  const params = useParams();
  const dispatch = useDispatch();
  const ridersError = useSelector((state) => state.merchant.ridersError);
  const ridersStatus = useSelector((state) => state.merchant.ridersStatus);
  const riders = useSelector((state) => state.merchant.riders);
  const ridersData = riders.map((i) => {
    return {
      key: i.id,
      name: i?.user?.name,
      address: i?.user?.address,
      phone: i?.user?.phone,
      bike: i.plate_no,
      status: i.status,
      createdAt: new Date(i.created_at).toLocaleDateString(),
    };
  });

  //sorting
  let sortedRiders = ridersData;

  sortedRiders = _.orderBy(ridersData, ["name"], [sortBy]);
  let filteredRiders = sortedRiders;

  if (filterProp && filterValue) {
    filteredRiders = _.filter(sortedRiders, function (o) {
      return o[filterProp].toLowerCase() === filterValue.toLowerCase();
    });
  }
  const searcher = new FuzzySearch(filteredRiders, [
    "bike",
    "phone",
    "name",
    "address",
  ]);

  let searchResult = filteredRiders;
  if (searchQuery) {
    searchResult = searcher.search(searchQuery);
  }
  const ridersPagination = useSelector(
    (state) => state.merchant.ridersPagination
  );
  //sorting
  const [current, setCurrent] = useState(1);

  useEffect(() => {
    dispatch(getMerchantRiders(params.merchantId, current));
  }, [dispatch, params.merchantId, current]);

  const changePage = async (page) => {
    setCurrent(page);
    dispatch(getMerchantRiders(params.merchantId, page));
  };
  useEffect(() => {
    if (ridersStatus === "failed") {
      if (ridersError) {
        message.error(ridersError);
      } else {
        message.error("Could not load merchant riders. Try Reload!");
      }
    }
    dispatch(changeMerchantValue("ridersError", ""));
  }, [ridersStatus, ridersError, dispatch]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;
  return (
    <div>
      <div style={{ marginBottom: 16 }}>
        <span style={{ marginLeft: 8 }}>
          {hasSelected ? `Selected ${selectedRowKeys.length} items` : ""}
        </span>
      </div>
      <Table
        scroll={{ x: true }}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={searchResult}
        pagination={{
          current,
          defaultCurrent: ridersPagination?.current_page,
          total: ridersPagination?.total,
          onChange: (page, pageSize) => changePage(page),
          pageSize: Number(ridersPagination?.per_page),
          showSizeChanger: false,
        }}
      />
    </div>
  );
}
