/** @format */

import React from "react";
import "./App.css";
import Dashboard from "./screens/Dashboard/Dashboard";
import Login from "./screens/Login/Login";
import "react-calendar/dist/Calendar.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store/app/store";
import OnboardNewBusiness from "./screens/OnboardNewBusiness/OnboardNewBusiness";
import SignUp from "./screens/SignUp/SignUp";
import RecoverPassword from "./screens/RecoverPassword/RecoverPassword";
import ResetPassword from "./screens/ResetPassword/ResetPassword";
import MerchantConfig from "./screens/MerchantConfig/MerchantConfig";

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route
            path='/company-admin/business'
            component={OnboardNewBusiness}
          />
          <Route
            path='/company-admin/reset-password'
            component={ResetPassword}
          />
          <Route
            path='/company-admin/recover-password'
            component={RecoverPassword}
          />
          <Route path='/company-admin/sign-up' component={SignUp} />
          <Route
            path='/company-admin/merchant-config'
            component={MerchantConfig}
          />
          <Route path='/company-admin/login' component={Login} />
          <Route path='/company-admin' component={Dashboard} />
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
