/** @format */

import React, { useEffect, useState } from "react";
import { Table, Tag } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { loadTransactions } from "../store/dashboardSlice";
import _ from "lodash";
import FuzzySearch from "fuzzy-search";
import { currencyFormatter } from "../assets/js/nFormatter";

const TransactionsTable = ({
  sortBy,
  filterProp,
  filterValue,
  searchQuery,
  dateRange,
}) => {
  const dispatch = useDispatch();
  const [current, setCurrent] = useState(1);

  useEffect(() => {
    if (dateRange) {
      dispatch(loadTransactions(current, dateRange[0], dateRange[1]));
    } else {
      dispatch(loadTransactions(current));
    }
  }, [dispatch, dateRange, current]);
  const columns = [
    {
      title: "REF.ID",
      dataIndex: "ref",
    },
    {
      title: "DETAILS",
      dataIndex: "details",
    },
    {
      title: "COMPANY",
      dataIndex: "company",
    },
    {
      title: "AMOUNT",
      dataIndex: "amount",
      render: (tag) => {
        return <span>{currencyFormatter.format(tag)}</span>;
      },
    },
    {
      title: "TRANS.STATUS",
      dataIndex: "status",
      render: (tag) => {
        let color = "volcano";
        if (
          tag.toLowerCase() === "successful" ||
          tag.toLowerCase() === "success"
        ) {
          color = "green";
        }
        let text = "Failed";
        if (
          tag.toLowerCase() === "successful" ||
          tag.toLowerCase() === "success"
        ) {
          text = "Success";
        }

        return (
          <Tag color={color} key={tag} className='capitalize'>
            {text}
          </Tag>
        );
      },
    },
    {
      title: "DATE",
      dataIndex: "createdAt",
    },
  ];

  const transactionsPagination = useSelector(
    (state) => state.dashboard.transactionsPagination
  );
  const transactions = useSelector((state) => state.dashboard.transactions);
  const transactionsData = transactions.map((i, index) => {
    return {
      key: index,
      ref: i.transaction_ref,
      details: i.details,
      company: i.company?.name,
      channel: i.channel,
      amount: i.amount,
      status: i.status,
      createdAt: new Date(i.created_at).toLocaleDateString(),
    };
  });
  //sorting
  let sortedTransaction = transactionsData;

  sortedTransaction = _.orderBy(transactionsData, ["ref"], [sortBy]);
  let filteredTransaction = sortedTransaction;

  if (filterProp && filterValue) {
    filteredTransaction = _.filter(sortedTransaction, function (o) {
      return o[filterProp].toLowerCase() === filterValue.toLowerCase();
    });
  }
  const searcher = new FuzzySearch(filteredTransaction, [
    "company",
    "amount",
    "details",
    "ref",
  ]);

  let searchResult = filteredTransaction;
  if (searchQuery) {
    searchResult = searcher.search(searchQuery);
  } //sorting
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  const changePage = async (page) => {
    setCurrent(page);
    dispatch(loadTransactions(page));
  };

  return (
    <div>
      <div style={{ marginBottom: 16 }}>
        <span style={{ marginLeft: 8 }}>
          {hasSelected ? `Selected ${selectedRowKeys.length} items` : ""}
        </span>
      </div>
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={searchResult}
        pagination={{
          current,
          defaultCurrent: transactionsPagination?.current_page,
          total: transactionsPagination?.total,
          onChange: (page, pageSize) => changePage(page),
          pageSize: Number(transactionsPagination?.per_page),
          showSizeChanger: false,
        }}
      />
    </div>
  );
};

export default TransactionsTable;
