/** @format */

import React, { useEffect } from "react";
import prihmex_logo from "../../assets/prihmex-logo.svg";
import "./style.css";
import { Form, Input, Button, Spin, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
// import { useState } from "react";
// import { ReactComponent as EmailSent } from "../../assets/EmailSent.svg";
import { ReactComponent as Logo2 } from "../../assets/Logo2.svg";
import { changeAuthInput, recoverPassword } from "../../store/authSlice";

export default function RecoverPassword() {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const recoverError = useSelector((state) => state.user.recoverError);
  const recoverStatus = useSelector((state) => state.user.recoverStatus);
  const recoverLoading = useSelector((state) => state.user.recoverLoading);
  // const [sent, setSent] = useState(false);

  useEffect(() => {
    if (recoverStatus === "failed") {
      if (recoverError) {
        message.error(recoverError);
      } else {
        message.error("Error recorvering password, try again!");
      }
    }
    if (recoverStatus === "success") {
      message.success("Email sent!");
    }
    dispatch(changeAuthInput("recoverStatus", "pending"));
    dispatch(changeAuthInput("recoverError", ""));
  }, [recoverStatus, dispatch, recoverError]);
  return (
    <div id='loginScrreen'>
      <div className='image-section '>
        <div className='overlay-blue d-flex justify-content-center align-items-center'>
          <img src={prihmex_logo} alt='' />
        </div>
      </div>
      <div className='text-section'>
        <Logo2 style={{ marginBottom: 30 }} />

        <div>
          <h1>Recover Password</h1> <Spin spinning={recoverLoading} />
          <p className='mb-5'>
            Fill the details below to recover your password
          </p>
          <Form
            form={form}
            layout='vertical'
            initialValues={{}}
            size='large'
            onFinish={(values) => {
              dispatch(recoverPassword(values));
            }}>
            <Form.Item
              required
              name='username'
              rules={[
                {
                  required: true,
                  message: "Username is required!",
                },
              ]}
              className='my-2'>
              <Input placeholder='bola@admin.primhex.com' />
            </Form.Item>

            <Form.Item className='my-3'>
              <Button
                type='primary'
                color='#404c96'
                style={{ backgroundColor: "#404c96", marginTop: 30 }}
                htmlType='submit'>
                {recoverLoading ? (
                  <Spin spinning={recoverLoading} />
                ) : (
                  "Send Intructions"
                )}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>

      {/* <div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <EmailSent style={{ marginBottom: 30 }} />
        </div>
        <h1>Password Reset Instruction Sent</h1>
        <p className="mb-5">
          We have sent a password recovery instructions to your email
        </p>

        <Button
          type="primary"
          color="#404c96"
          style={{ backgroundColor: "#404c96", marginTop: 30 }}
          htmlType="submit"
        >
          Continue
        </Button>
      </div> */}
    </div>
  );
}
