/** @format */

import React, { useState } from "react";
import NavHeader from "../../components/NavHeader/NavHeader";
import "./style.css";
import { Menu } from "antd";
import MerchantOverview from "../MerchantOverview/MerchantOverview";
import MerchantUsers from "../MerchantUsers/MerchantUsers";
import MerchantTransactions from "../MerchantTransactions/MerchantTransactions";
import MerchantRiders from "../MerchantRiders/MerchantRiders";
import MerchantBikes from "../MerchantBikes/MerchantBikes";
import MerchantSettlements from "../MerchantSettlements/MerchantSettlements";

export default function MerchantScreen() {
  const [current, setCurrent] = useState("one");
  return (
    <div>
      <NavHeader
        children={
          <Menu
            selectedKeys={[current]}
            mode='horizontal'
            style={{
              backgroundColor: "transparent",
              borderBottomWidth: 0,
              marginRight: "auto",
              marginLeft: 20,
            }}>
            <Menu.Item key='one' onClick={(key) => setCurrent(key.key)}>
              Overview
            </Menu.Item>
            <Menu.Item key='two' onClick={(key) => setCurrent(key.key)}>
              Users
            </Menu.Item>
            <Menu.Item key='three' onClick={(key) => setCurrent(key.key)}>
              Transactions
            </Menu.Item>
            <Menu.Item key='four' onClick={(key) => setCurrent(key.key)}>
              Riders
            </Menu.Item>
            <Menu.Item key='five' onClick={(key) => setCurrent(key.key)}>
              Bikes
            </Menu.Item>
            {/* <Menu.Item key="six" onClick={(key) => setCurrent(key.key)}>
              Settlements
            </Menu.Item> */}
          </Menu>
        }
      />
      <MerchantOverview visible={current === "one"} setCurrent={setCurrent} />
      <MerchantUsers visible={current === "two"} />
      <MerchantTransactions visible={current === "three"} />
      <MerchantRiders visible={current === "four"} />
      <MerchantBikes visible={current === "five"} />
      <MerchantSettlements visible={current === "six"} />
    </div>
  );
}
