/** @format */

import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
// import jwt from "jsonwebtoken";
// import moment from "moment";

const slice = createSlice({
  name: "user",
  initialState: {
    profile: {},
    redirect: "",
    status: "pending",
    error: "",
    loading: false,
    recoverError: "",
    recoverStatus: "pending",
    recoverLoading: false,
    resetError: "",
    resetStatus: "pending",
    resetLoading: false,
    logOutError: "",
    logOutStatus: "pending",
    logOutLoading: false,
  },
  reducers: {
    changeInput: (user, action) => {
      user[action.payload.name] = action.payload.value;
    },
    recoverPasswordStart: (user, action) => {
      user.recoverLoading = true;
      user.recoverStatus = "pending";
    },
    recoverPasswordSuccess: (user, action) => {
      user.recoverLoading = false;
      user.recoverStatus = "success";
    },
    recoverPasswordFailed: (user, action) => {
      user.recoverLoading = false;
      user.recoverError = action.payload?.response?.data?.message;
      user.recoverStatus = "failed";
    },
    resetPasswordStart: (user, action) => {
      user.resetLoading = true;
      user.resetStatus = "pending";
    },
    resetPasswordSuccess: (user, action) => {
      user.resetLoading = false;
      user.resetStatus = "success";
    },
    resetPasswordFailed: (user, action) => {
      user.resetLoading = false;
      user.resetError = action.payload?.response?.data?.message;
      user.resetStatus = "failed";
    },
    signUpStart: (user, action) => {
      user.loading = true;
      user.status = "pending";
    },
    signUpSuccess: (user, action) => {
      user.loading = false;
      user.profile = action.payload.user;
      user.status = "success";
      localStorage.setItem("primhex:token", action.payload.token);
      if (user.redirect) {
        window.location = user.redirect;
      }
    },
    signUpFailed: (user, action) => {
      user.loading = false;
      user.error = action.payload?.response?.data?.message;
      user.status = "failed";
    },
    authStart: (user, action) => {
      user.loading = true;
      user.status = "fail";
    },
    authSuccess: (user, action) => {
      user.loading = false;
      user.profile = action.payload;
      user.status = "success";
      localStorage.setItem("primhex:token", action.payload.token);
      localStorage.setItem("primhex:user", JSON.stringify(action.payload));
      if (user.redirect) {
        window.location = user.redirect;
      } else {
        window.location = "/company-admin";
      }
    },
    authFailed: (user, action) => {
      user.loading = false;
      user.error = action.payload?.response?.data?.message;
      user.status = "failed";
    },
    logOutStart: (user, action) => {
      user.logOutLoading = true;
      user.logOutStatus = "fail";
    },
    logOutSuccess: (user, action) => {
      user.logOutLoading = false;
      user.profile = {};
      user.logOutStatus = "success";
      localStorage.removeItem("primhex:token");
      // localStorage.setItem(
      //   "primhex:token",
      //   "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL3RhcGkucHJpbWhleC5jb20vdjEvYXV0aC9sb2dpbiIsImlhdCI6MTYzNjc4NTkwMCwiZXhwIjoxNjY4MzIxOTAwLCJuYmYiOjE2MzY3ODU5MDAsImp0aSI6IkRramFyNjFHbEw0eDB4cWQiLCJzdWIiOiI3NzE4MzAwOS1kNjBiLTQ3ZTktYTc4YS03MjI0N2JjOGE3ZmEiLCJwcnYiOiIyM2JkNWM4OTQ5ZjYwMGFkYjM5ZTcwMWM0MDA4NzJkYjdhNTk3NmY3In0.6sreomW1kAmHo9alD443-t4PArSH4E_BvfJjcoBww30"
      // );
      localStorage.removeItem("primhex:user");
      window.location = "/company-admin/login";
    },
    logOutFailed: (user, action) => {
      user.logOutLoading = false;
      user.error = action.payload?.response?.data?.message;
      user.logOutStatus = "failed";
    },
    authRemoved: (user, action) => {
      localStorage.removeItem("primhex:token");
      user.profile = {};
    },
  },
});

export const {
  authStart,
  authSuccess,
  authFailed,
  signUpStart,
  signUpSuccess,
  signUpFailed,
  authRemoved,

  changeInput,
  resetPasswordFailed,
  resetPasswordStart,
  resetPasswordSuccess,
  recoverPasswordFailed,
  recoverPasswordStart,
  recoverPasswordSuccess,
  logOutFailed,
  logOutStart,
  logOutSuccess,
} = slice.actions;

export default slice.reducer;

export const changeAuthInput = (name, value) => (dispatch) => {
  dispatch({ type: changeInput.type, payload: { name, value } });
};

export const logUserIn = (username, password) => (dispatch) => {
  dispatch(
    apiCallBegan({
      url: "v1/auth/login",
      method: "post",
      data: { username, password },

      onStart: authStart.type,
      onSuccess: authSuccess.type,
      onError: authFailed.type,
    })
  );
};

export const logUserOut = () => (dispatch) => {
  dispatch(
    apiCallBegan({
      url: "v1/auth/logout",
      method: "post",
      data: { token: localStorage.getItem("primhex:token") },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("primhex:token")}`,
      },
      onStart: logOutStart.type,
      onSuccess: logOutSuccess.type,
      onError: logOutFailed.type,
    })
  );
};

export const recoverPassword = (data) => (dispatch) => {
  dispatch(
    apiCallBegan({
      url: "v1/auth/forgot-password",
      method: "post",
      data,

      onStart: recoverPasswordStart.type,
      onSuccess: recoverPasswordSuccess.type,
      onError: recoverPasswordFailed.type,
    })
  );
};
export const changePassword = (data) => (dispatch) => {
  dispatch(
    apiCallBegan({
      url: "v1/change-password",
      method: "post",
      data,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("primhex:token")}`,
      },
      onStart: resetPasswordStart.type,
      onSuccess: resetPasswordSuccess.type,
      onError: resetPasswordFailed.type,
    })
  );
};
export const resetPassword = (data) => (dispatch) => {
  dispatch(
    apiCallBegan({
      url: "v1/auth/reset-password",
      method: "post",
      data: { ...data, token: localStorage.getItem("primhex:token") },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("primhex:token")}`,
      },
      onStart: resetPasswordStart.type,
      onSuccess: resetPasswordSuccess.type,
      onError: resetPasswordFailed.type,
    })
  );
};

// export const logUserOut = () => (dispatch) => {
//   window.location = "/company-admin/login";

//   localStorage.removeItem("primhex:user");
//   return localStorage.removeItem("primhex:token");
// };

export const getLoggedInUser = () => {
  const user = localStorage.getItem("primhex:user");
  return JSON.parse(user);
};
