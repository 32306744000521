/** @format */

import React, { useEffect, useState } from "react";
import { Spin, Table } from "antd";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { deleteRole, loadRoles } from "../store/dashboardSlice";
import FuzzySearch from "fuzzy-search";

const RolesListTable = ({ searchQuery, role, setRole }) => {
  const dispatch = useDispatch();
  const rolesLoading = useSelector((state) => state.dashboard.rolesLoading);
  const [current, setCurrent] = useState(1);

  useEffect(() => {
    dispatch(loadRoles(current));
  }, [dispatch, current]);
  const changePage = async (page) => {
    setCurrent(page);
    dispatch(loadRoles(page));
  };
  const columns = [
    {
      title: "ROLE",
      dataIndex: "role",
    },
    {
      title: "DESCRIPTION",
      dataIndex: "description",
    },
    {
      title: "USERS",
      dataIndex: "users",
    },

    {
      title: () => <Spin spinning={rolesLoading} />,
      dataIndex: "delete",
      render: (i) => {
        return (
          <FontAwesomeIcon
            icon={faTrash}
            color='#F95050'
            className='cursor'
            onClick={() => {
              dispatch(deleteRole(i.uuid));
            }}
          />
        );
      },
    },
    {
      title: "",
      dataIndex: "edit",
      render: (i) => {
        return (
          <Link
            to={`/company-admin/management/${i.id}`}
            style={{ color: "#7677AE" }}
            onClick={() => setRole(i)}>
            Manage Role
            <FontAwesomeIcon
              icon={faArrowRight}
              color='#7677AE'
              className='ml-10'
            />
          </Link>
        );
      },
    },
  ];
  const rolesPagination = useSelector(
    (state) => state.dashboard.rolesPagination
  );
  const roles = useSelector((state) => state.dashboard.roles);
  const roleData = roles.map((i) => {
    return {
      key: i.id,
      description: i.description,
      role: i.name,
      edit: i,
      delete: i,
      users: i.users_counts,
    };
  });
  const searcher = new FuzzySearch(roleData, ["description", "role"]);
  let searchResult = roleData;
  if (searchQuery) {
    searchResult = searcher.search(searchQuery);
  }

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  return (
    <div>
      <div style={{ marginBottom: 16 }}>
        <span style={{ marginLeft: 8 }}>
          {hasSelected ? `Selected ${selectedRowKeys.length} items` : ""}
        </span>
      </div>
      <Table
        rowSelection={rowSelection}
        columns={columns}
        pagination={{
          current,
          defaultCurrent: rolesPagination?.current_page,
          total: rolesPagination?.total,
          onChange: (page, pageSize) => changePage(page),
          pageSize: Number(rolesPagination?.per_page),
          showSizeChanger: false,
        }}
        dataSource={searchResult}
      />
    </div>
  );
};

export default RolesListTable;
