/** @format */

import React, { useState } from "react";
import "./style.css";
import { Button, Input, Modal, DatePicker } from "antd";
import { Row, Col } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faSort } from "@fortawesome/free-solid-svg-icons";
import { Menu, Dropdown, Space } from "antd";
import MerchantRidersTable from "../../components/MerchantRidersTable";
import { useSelector } from "react-redux";

const { Search } = Input;
const { RangePicker } = DatePicker;

export default function MerchantRiders({ visible = false }) {
  const merchant = useSelector((state) => state.merchant.merchant);

  const [visiblePop, setVisiblePop] = React.useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);

  const [sortBy, setSortBy] = useState("");
  const [filterProp, setFilterProp] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [dateRange, setDateRange] = useState(null);

  const showModal = () => {
    setVisiblePop(true);
  };

  const handleOk = () => {
    setConfirmLoading(true);
    setTimeout(() => {
      setVisiblePop(false);
      setConfirmLoading(false);
    }, 2000);
  };
  const handleCancel = () => {
    setVisiblePop(false);
  };

  const menu = (
    <Menu>
      <Menu.Item key='1' onClick={() => setSortBy("asc")}>
        Alphabetically (A-Z)
      </Menu.Item>
      <Menu.Item key='2' onClick={() => setSortBy("desc")}>
        Alphabetically (Z-A)
      </Menu.Item>
    </Menu>
  );
  const menuTwo = (
    <Menu>
      <Menu.Item
        key='7'
        onClick={() => {
          setFilterProp("");
          setFilterValue("");
        }}>
        All
      </Menu.Item>
      <Menu.Item key='1' onClick={showModal}>
        Date
      </Menu.Item>
      <Menu.Item
        key='2'
        onClick={() => {
          setFilterProp("status");
          setFilterValue("Active");
        }}>
        Status - Active
      </Menu.Item>
      <Menu.Item
        key='3'
        onClick={() => {
          setFilterProp("status");
          setFilterValue("Inactive");
        }}>
        Status - Inactive
      </Menu.Item>
    </Menu>
  );

  if (!visible) return null;
  return (
    <div>
      <div className='large-header secondary pt-30'>
        {merchant.business_name} - Riders
      </div>
      <div className='primary-header'>
        Here are the riders registered on {merchant.business_name}
      </div>
      <Row
        gutter={16}
        style={{ marginTop: 20, justifyContent: "space-between" }}>
        <Col
          className='gutter-row'
          lg={{ span: 12 }}
          sm={{ span: 6 }}
          md={{ span: 6 }}
          xs={{ span: 8 }}>
          <Search
            placeholder='input search text'
            onChange={(e) => {
              setSearchQuery(e.target.value);
            }}
            enterButton
            color='green'
            style={{ backgroundColor: "#5322DB" }}
          />
        </Col>
        <Col className='gutter-row'>
          <Space wrap>
            <Dropdown overlay={menu}>
              <Button
                type='text'
                style={{ backgroundColor: "#7677AE", color: "white" }}
                className='text-white br-5'>
                Sort{" "}
                <FontAwesomeIcon icon={faSort} className='ml-10 text-white' />
              </Button>
            </Dropdown>
            <Dropdown overlay={menuTwo}>
              <Button
                type='text'
                style={{ backgroundColor: "#5322DB" }}
                className='text-white br-5'>
                Filter{" "}
                <FontAwesomeIcon icon={faFilter} className='ml-10 text-white' />
              </Button>
            </Dropdown>
            {/* <Dropdown overlay={menuThree}>
              <Button
                type='text'
                style={{ backgroundColor: "#131573" }}
                className='text-white br-5'>
                Actions{" "}
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className='ml-10 text-white'
                />
              </Button>
            </Dropdown> */}
          </Space>
        </Col>
      </Row>
      <MerchantRidersTable
        sortBy={sortBy}
        filterProp={filterProp}
        filterValue={filterValue}
        searchQuery={searchQuery}
        dateRange={dateRange}
      />
      <Modal
        title='Filter By Date'
        visible={visiblePop}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}>
        <RangePicker
          onChange={(date, dateString) => {
            if (dateString) {
              if (dateString[0] && dateString[1]) {
                setDateRange(dateString);
              }
            }
          }}
        />
      </Modal>
    </div>
  );
}
