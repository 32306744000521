/** @format */

import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";

const slice = createSlice({
  name: "dashboard",
  initialState: {
    stat: {},
    statError: "",
    statLoading: false,
    report: {},
    reportError: "",
    reportLoading: false,
    //users
    usersPagination: {},
    users: [],
    userAddError: "",
    userAddLoading: false,
    userupdateError: "",
    userupdateLoading: false,
    userdeleteError: "",
    userdeleteLoading: false,
    usersError: "",
    usersLoading: false,
    addUserStatus: "pending",
    deleteUserStatus: "pending",
    updateUserStatus: "pending",
    //roles
    addRoleError: "",
    addRoleStatus: "pending",
    deleteRoleError: "",
    deleteRoleStatus: "pending",
    updateRoleError: "",
    updateRoleStatus: "pending",
    rolesPagination: {},
    rolesError: "",
    roles: [],
    rolesLoading: false,
    //transactions
    transactionsPagination: {},
    transactions: [],
    transactionsError: "",
    transactionsLoading: false,
    //merchant
    merchant: {},
    merchantError: "",
    merchantLoading: false,
    loadMerchantStatus: "pending",
    //trackDetals
    trackDetails: {},
    trackDetailsError: "",
    trackDetailsLoading: false,
    loadtrackDetailsStatus: "pending",
    //merchants
    merchantsPagination: {},
    merchantsError: "",
    merchants: [],
    merchantsLoading: false,
    loadMerchantsStatus: "pending",
    //features
    featuresPagination: {},
    featuresError: "",
    features: [],
    featuresLoading: false,
    loadFeaturesStatus: "pending",
    addFeaturesError: "",
    addFeaturesStatus: "pending",
    featuresAdding: false,
    featurePlanUpdating: false,
    updateFeaturePlanError: "",
    updateFeaturePlanStatus: "pending",
    featureDeleteLoading: false,
    deleteFeaturePlanError: "",
    deleteFeaturePlanStatus: "pending",

    //subscriptionFeatures
    loadsubscriptionFeaturesStatus: "pending",
    subscriptionFeaturesLoading: false,
    subscriptionFeaturesPagination: {},
    subscriptionFeaturesError: "",
    subscriptionFeatures: [],
    //subscriptions
    subscriptionsPagination: {},
    subscriptionsError: "",
    subscriptions: [],
    subscriptionsLoading: false,
    loadsubscriptionsStatus: "pending",
    addSubscriptionsError: "",
    addSubscriptionsStatus: "pending",
    subscriptionsAdding: false,
    subscriptionPlanUpdating: false,
    updateSubscriptionPlanError: "",
    updateSubscriptionPlanStatus: "pending",
    subscriptionDeleteLoading: false,
    deleteSubscriptionPlanError: "",
    deleteSubscriptionPlanStatus: "pending",
    //countries
    countries: [],
    countriesLoading: false,
    loadcountriesError: "",
    loadcountriesStatus: "pending",
    //states
    states: [],
    statesLoading: false,
    loadstatesError: "",
    loadstatesStatus: "pending",
    //notifications
    notifications: [],
    notificationsLoading: false,
    loadnotificationsError: "",
    loadnotificationsStatus: "pending",
  },
  reducers: {
    changeValue: (dashboard, action) => {
      dashboard[action.payload.key] = action.payload.value;
    },
    statRequested: (dashboard, action) => {
      dashboard.statLoading = true;
    },
    statReceived: (dashboard, action) => {
      dashboard.stat = action.payload;
      dashboard.statLoading = false;
    },
    statRequestFailed: (dashboard, action) => {
      dashboard.statError = action.payload?.response?.data?.message;
      dashboard.statLoading = false;
    },
    reportRequested: (dashboard, action) => {
      dashboard.reportLoading = true;
    },
    reportReceived: (dashboard, action) => {
      dashboard.report = action.payload;
      dashboard.reportLoading = false;
    },
    reportRequestFailed: (dashboard, action) => {
      dashboard.reportError = action.payload?.response?.data?.message;
      dashboard.reportLoading = false;
    },
    usersRequested: (dashboard, action) => {
      dashboard.usersLoading = true;
    },
    usersReceived: (dashboard, action) => {
      dashboard.usersPagination = action.payload.pagination;
      dashboard.users = action.payload.data;
      dashboard.usersLoading = false;
    },
    usersRequestFailed: (dashboard, action) => {
      dashboard.usersError = action.payload?.response?.data?.message;
      dashboard.usersLoading = false;
    },
    trackDetailsRequested: (dashboard, action) => {
      dashboard.trackDetailsLoading = true;
      dashboard.loadtrackDetailsStatus = "pending";
    },
    trackDetailsReceived: (dashboard, action) => {
      dashboard.trackDetails = action.payload;
      dashboard.trackDetailsLoading = false;
      dashboard.loadtrackDetailsStatus = "success";
    },
    trackDetailsRequestFailed: (dashboard, action) => {
      dashboard.trackDetailsLoading = false;
      dashboard.trackDetailsError = action.payload?.response?.data?.message;
      dashboard.loadtrackDetailsStatus = "failed";
    },
    notificationsRequested: (dashboard, action) => {
      dashboard.notificationsLoading = true;
      dashboard.loadnotificationsStatus = "pending";
    },
    notificationsReceived: (dashboard, action) => {
      dashboard.notifications = action.payload.data;
      dashboard.notificationsLoading = false;
      dashboard.loadnotificationsStatus = "success";
    },
    notificationsRequestFailed: (dashboard, action) => {
      dashboard.notificationsLoading = false;
      dashboard.loadnotificationsError =
        action.payload?.response?.data?.message;
      dashboard.loadnotificationsStatus = "failed";
    },
    statesRequested: (dashboard, action) => {
      dashboard.statesLoading = true;
      dashboard.loadstatesStatus = "pending";
    },
    statesReceived: (dashboard, action) => {
      dashboard.states = action.payload;
      dashboard.statesLoading = false;
      dashboard.loadstatesStatus = "success";
    },
    statesRequestFailed: (dashboard, action) => {
      dashboard.statesLoading = false;
      dashboard.loadstatesError = action.payload?.response?.data?.message;
      dashboard.loadstatesStatus = "failed";
    },
    countriesRequested: (dashboard, action) => {
      dashboard.countriesLoading = true;
      dashboard.loadcountriesStatus = "pending";
    },
    countriesReceived: (dashboard, action) => {
      dashboard.countries = action.payload;
      dashboard.countriesLoading = false;
      dashboard.loadcountriesStatus = "success";
    },
    countriesRequestFailed: (dashboard, action) => {
      dashboard.countriesLoading = false;
      dashboard.loadcountriesStatus = "failed";
      dashboard.loadcountriesError = action.payload?.response?.data?.message;
    },
    usersAddStart: (dashboard, action) => {
      dashboard.userAddLoading = true;
      dashboard.addUserStatus = "pending";
    },
    usersAdded: (dashboard, action) => {
      dashboard.users = [action.payload, ...dashboard.users];
      dashboard.userAddLoading = false;
      dashboard.addUserStatus = "success";
    },
    usersAddFailed: (dashboard, action) => {
      dashboard.userAddLoading = false;
      dashboard.addUserError = action.payload.response?.data?.message;
      dashboard.addUserStatus = "failed";
    },
    usersupdateStart: (dashboard, action) => {
      dashboard.userupdateLoading = true;
      dashboard.updateUserStatus = "pending";
    },
    usersupdated: (dashboard, action) => {
      const currentUsers = dashboard.users;
      const userIndex = currentUsers.findIndex(
        (i) => i.id === action.payload.id
      );
      if (userIndex !== -1) {
        currentUsers.splice(userIndex, 1, action.payload);
      }
      const currentLoggedInUser = JSON.parse(
        localStorage.getItem("primhex:user")
      );

      if (currentLoggedInUser.uuid === action.payload.uuid) {
        localStorage.setItem("primhex:user", JSON.stringify(action.payload));
      }
      dashboard.users = [...currentUsers];
      dashboard.userupdateLoading = false;
      dashboard.updateUserStatus = "success";
    },
    usersupdateFailed: (dashboard, action) => {
      dashboard.userupdateLoading = false;
      dashboard.updateUserStatus = "failed";
      dashboard.userupdateError = action.payload.response?.data?.message;
    },
    usersdeleteStart: (dashboard, action) => {
      dashboard.userdeleteLoading = true;
      dashboard.deleteUserStatus = "pending";
    },
    usersdeleted: (dashboard, action) => {
      const currentUsers = dashboard.users;
      const userIndex = currentUsers.findIndex(
        (i) => i.id === action.payload.id
      );
      if (userIndex !== -1) {
        currentUsers.splice(userIndex, 1);
      }
      dashboard.users = [...currentUsers];
      dashboard.userdeleteLoading = false;
      dashboard.deleteUserStatus = "success";
      setTimeout(() => {
        window.location.reload();
      }, 200);
    },
    usersdeleteFailed: (dashboard, action) => {
      dashboard.userdeleteLoading = false;
      dashboard.deleteUserStatus = "failed";
      dashboard.userdeleteError = action.payload.response?.data?.message;
    },
    transactionsRequested: (dashboard, action) => {
      dashboard.transactionsLoading = true;
    },
    transactionsReceived: (dashboard, action) => {
      dashboard.transactionsPagination = action.payload.pagination;
      dashboard.transactions = action.payload.data;
      dashboard.transactionsLoading = false;
    },
    transactionsRequestFailed: (dashboard, action) => {
      dashboard.transactionsLoading = false;
      dashboard.transactionsError = action.payload.response?.data?.message;
    },
    merchantsRequested: (dashboard, action) => {
      dashboard.loadMerchantsStatus = "pending";
      dashboard.merchantsLoading = true;
    },
    merchantsReceived: (dashboard, action) => {
      dashboard.merchantsPagination = action.payload.pagination;
      dashboard.merchants = action.payload.data;
      dashboard.loadMerchantsStatus = "success";
      dashboard.merchantsLoading = false;
    },
    merchantsRequestFailed: (dashboard, action) => {
      dashboard.loadMerchantsStatus = "failed";
      dashboard.merchantsLoading = false;
      dashboard.merchantsError = action.payload.response?.data?.message;
    },




    featuresRequested: (dashboard, action) => {
      dashboard.loadFeaturesStatus = "pending";
      dashboard.featuresLoading = true;
    },
    featuresReceived: (dashboard, action) => {
      dashboard.featuresPagination = action.payload.pagination;
      dashboard.features = action.payload.data;
      dashboard.loadFeaturesStatus = "success";
      dashboard.featuresLoading = false;
    },
    featuresRequestFailed: (dashboard, action) => {
      dashboard.loadFeaturesStatus = "failed";
      dashboard.featuresLoading = false;
      dashboard.featuresError = action.payload.response?.data?.message;
    },



    subscriptionFeaturesRequested: (dashboard, action) => {
      dashboard.loadsubscriptionFeaturesStatus = "pending";
      dashboard.subscriptionFeaturesLoading = true;
    },
    subscriptionFeaturesReceived: (dashboard, action) => {
      dashboard.subscriptionFeaturesPagination = action.payload.pagination;
      dashboard.subscriptionFeatures = action.payload.data;
      dashboard.loadsubscriptionFeaturesStatus = "success";
      dashboard.subscriptionFeaturesLoading = false;
    },
    subscriptionFeaturesRequestFailed: (dashboard, action) => {
      dashboard.loadsubscriptionFeaturesStatus = "failed";
      dashboard.subscriptionFeaturesLoading = false;
      dashboard.subscriptionFeaturesError =
        action.payload.response?.data?.message;
    },
    subscriptionAddStart: (dashboard, action) => {
      dashboard.addSubscriptionsStatus = "pending";
      dashboard.subscriptionsAdding = true;
    },
    subscriptionAdded: (dashboard, action) => {
      dashboard.subscriptions = [...dashboard.subscriptions, action.payload];
      dashboard.addSubscriptionsStatus = "success";
      dashboard.subscriptionsAdding = false;
    },
    subscriptionAddFailed: (dashboard, action) => {
      dashboard.addSubscriptionsStatus = "failed";
      dashboard.subscriptionsAdding = false;
      dashboard.addSubscriptionsError = action.payload.response?.data?.message;
    },
    subscriptionsRequested: (dashboard, action) => {
      dashboard.loadsubscriptionsStatus = "pending";
      dashboard.subscriptionsLoading = true;
    },
    subscriptionsReceived: (dashboard, action) => {
      dashboard.subscriptionsPagination = action.payload.pagination;
      dashboard.subscriptions = action.payload.data;
      dashboard.loadsubscriptionsStatus = "success";
      dashboard.subscriptionsLoading = false;
    },
    subscriptionsRequestFailed: (dashboard, action) => {
      dashboard.loadsubscriptionsStatus = "failed";
      dashboard.subscriptionsLoading = false;
      dashboard.subscriptionsError = action.payload.response?.data?.message;
    },
    merchantRequested: (dashboard, action) => {
      dashboard.loadMerchantStatus = "pending";
      dashboard.merchantLoading = true;
    },
    merchantReceived: (dashboard, action) => {
      dashboard.merchant = action.payload;
      dashboard.loadMerchantStatus = "success";
      dashboard.merchantLoading = false;
    },
    merchantRequestFailed: (dashboard, action) => {
      dashboard.loadMerchantStatus = "failed";
      dashboard.merchantLoading = false;
      dashboard.merchantError = action.payload.response?.data?.message;
    },
    subscriptionPlanUpdateStart: (dashboard, action) => {
      dashboard.subscriptionPlanUpdating = true;
      dashboard.updateSubscriptionPlanStatus = "pending";
    },
    subscriptionPlanUpdated: (dashboard, action) => {
      const currentSubscriptionPlans = dashboard.subscriptions;
      const subscriptionPlanIndex = currentSubscriptionPlans.findIndex(
        (i) => i.id === action.payload.id
      );
      if (subscriptionPlanIndex !== -1) {
        currentSubscriptionPlans.splice(
          subscriptionPlanIndex,
          1,
          action.payload
        );
      }
      dashboard.subscriptions = [...currentSubscriptionPlans];
      dashboard.updateSubscriptionPlanStatus = "success";
      dashboard.subscriptionPlanUpdating = false;
    },
    subscriptionPlanUpdateFailed: (dashboard, action) => {
      dashboard.updateSubscriptionPlanStatus = "failed";
      dashboard.subscriptionPlanUpdating = false;
      dashboard.updateSubscriptionPlanError =
        action.payload.response?.data?.message;
    },
    subscriptionPlanDeleteStart: (dashboard, action) => {
      dashboard.subscriptionDeleteLoading = true;
      dashboard.deleteSubscriptionPlanStatus = "pending";
    },
    subscriptionPlanDeleted: (dashboard, action) => {
      const currentSubscriptionPlans = dashboard.subscriptions;
      const subscriptionPlanIndex = currentSubscriptionPlans.findIndex(
        (i) => i.id === action.payload.id
      );
      if (subscriptionPlanIndex !== -1) {
        currentSubscriptionPlans.splice(subscriptionPlanIndex, 1);
      }
      dashboard.subscriptions = [...currentSubscriptionPlans];
      dashboard.deleteSubscriptionPlanStatus = "success";
      dashboard.subscriptionDeleteLoading = false;
      window.location.reload();
    },
    subscriptionPlanDeleteFailed: (dashboard, action) => {
      dashboard.deleteSubscriptionPlanStatus = "failed";
      dashboard.subscriptionDeleteLoading = false;
      dashboard.deleteSubscriptionPlanError =
        action.payload.response?.data?.message;
    },
    roleUpdateStart: (dashboard, action) => {
      dashboard.rolesLoading = true;
      dashboard.updateRoleStatus = "pending";
    },
    roleUpdated: (dashboard, action) => {
      const currentRoles = dashboard.roles;
      const roleIndex = currentRoles.findIndex(
        (i) => i.id === action.payload.id
      );
      if (roleIndex !== -1) {
        currentRoles.splice(roleIndex, 1, action.payload);
      }
      dashboard.roles = [...currentRoles];
      dashboard.updateRoleStatus = "success";
      dashboard.rolesLoading = false;
    },
    roleUpdateFailed: (dashboard, action) => {
      dashboard.updateRoleStatus = "failed";
      dashboard.rolesLoading = false;
      dashboard.updateRoleError = action.payload.response?.data?.message;
    },
    roleDeleteStart: (dashboard, action) => {
      dashboard.rolesLoading = true;
      dashboard.deleteRoleStatus = "pending";
    },
    roleDeleted: (dashboard, action) => {
      const currentRoles = dashboard.roles;
      const roleIndex = currentRoles.findIndex(
        (i) => i.id === action.payload.id
      );
      if (roleIndex !== -1) {
        currentRoles.splice(roleIndex, 1);
      }
      dashboard.roles = [...currentRoles];
      dashboard.deleteRoleStatus = "success";
      dashboard.rolesLoading = false;
      window.location.reload();
    },
    roleDeleteFailed: (dashboard, action) => {
      dashboard.deleteRoleStatus = "failed";
      dashboard.rolesLoading = false;
      dashboard.deleteRoleError = action.payload.response?.data?.message;
    },
    roleAddStart: (dashboard, action) => {
      dashboard.rolesLoading = true;
      dashboard.addRoleStatus = "pending";
    },
    roleAdded: (dashboard, action) => {
      dashboard.roles = [...dashboard.roles, action.payload];
      dashboard.addRoleStatus = "success";
      dashboard.rolesLoading = false;
    },
    roleAddFailed: (dashboard, action) => {
      dashboard.addRoleStatus = "failed";
      dashboard.rolesLoading = false;
      dashboard.addRoleError = action.payload.response?.data?.message;
    },
    rolesRequested: (dashboard, action) => {
      dashboard.rolesLoading = true;
    },
    rolesReceived: (dashboard, action) => {
      dashboard.rolesPagination = action.payload.pagination;
      dashboard.roles = action.payload.data;
      dashboard.rolesLoading = false;
    },
    rolesRequestFailed: (dashboard, action) => {
      dashboard.rolesLoading = false;
      dashboard.rolesError = action.payload.response?.data?.message;
    },
  },
});

export const {
  statReceived,
  statRequestFailed,
  statRequested,
  reportReceived,
  reportRequestFailed,
  reportRequested,
  usersReceived,
  usersRequestFailed,
  usersRequested,
  rolesReceived,
  rolesRequestFailed,
  rolesRequested,
  transactionsReceived,
  transactionsRequestFailed,
  transactionsRequested,
  usersAddFailed,
  usersAddStart,
  usersAdded,
  usersupdateFailed,
  usersupdateStart,
  usersupdated,
  usersdeleteFailed,
  usersdeleteStart,
  usersdeleted,
  roleAddFailed,
  roleAddStart,
  roleAdded,
  roleUpdateFailed,
  roleUpdateStart,
  roleUpdated,
  roleDeleteFailed,
  roleDeleted,
  roleDeleteStart,
  merchantsReceived,
  merchantsRequestFailed,
  merchantsRequested,
  changeValue,
  countriesReceived,
  countriesRequestFailed,
  countriesRequested,
  statesReceived,
  statesRequestFailed,
  statesRequested,
  merchantReceived,
  merchantRequestFailed,
  merchantRequested,
  trackDetailsReceived,
  trackDetailsRequestFailed,
  trackDetailsRequested,
  subscriptionsReceived,
  subscriptionsRequestFailed,
  subscriptionsRequested,
  subscriptionFeaturesReceived,
  subscriptionFeaturesRequestFailed,
  subscriptionFeaturesRequested,
  subscriptionAddFailed,
  subscriptionAdded,
  subscriptionAddStart,
  subscriptionPlanDeleteFailed,
  subscriptionPlanDeleteStart,
  subscriptionPlanDeleted,
  subscriptionPlanUpdateFailed,
  subscriptionPlanUpdateStart,
  subscriptionPlanUpdated,
  notificationsReceived,
  notificationsRequestFailed,
  notificationsRequested,
} = slice.actions;

export default slice.reducer;

//Action creators
export const readNotification = (id) => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      url: "/v1/notifications/read",
      method: "post",
      data: { id },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("primhex:token")}`,
      },
    })
  );
};
export const loadNotifications = () => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      url: "/v1/notifications",
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("primhex:token")}`,
      },
      onStart: notificationsRequested.type,
      onSuccess: notificationsReceived.type,
      onError: notificationsRequestFailed.type,
    })
  );
};
export const loadStat = () => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      url: "/v1/dashboard/stats",
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("primhex:token")}`,
      },
      onStart: statRequested.type,
      onSuccess: statReceived.type,
      onError: statRequestFailed.type,
    })
  );
};
export const loadReport = (month, year) => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      url: `/v1/dashboard/business-report?month=${month}&year=${year}`,
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("primhex:token")}`,
      },
      onStart: reportRequested.type,
      onSuccess: reportReceived.type,
      onError: reportRequestFailed.type,
    })
  );
};
export const loadUsers = (page) => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      // url: "v1/users",
      url: `v1/users?page=${page}`,
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("primhex:token")}`,
      },
      onStart: usersRequested.type,
      onSuccess: usersReceived.type,
      onError: usersRequestFailed.type,
    })
  );
};
export const addUser = (data) => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      url: "v1/users/create",
      method: "post",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("primhex:token")}`,
      },
      data,
      onStart: usersAddStart.type,
      onSuccess: usersAdded.type,
      onError: usersAddFailed.type,
    })
  );
};
export const updateUser = (id, data) => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      url: "v1/users/" + id + "/update",
      method: "put",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("primhex:token")}`,
      },
      data,
      onStart: usersupdateStart.type,
      onSuccess: usersupdated.type,
      onError: usersupdateFailed.type,
    })
  );
};
export const deleteUser = (id) => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      url: "v1/users/" + id + "/force-delete",
      method: "delete",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("primhex:token")}`,
      },
      onStart: usersdeleteStart.type,
      onSuccess: usersdeleted.type,
      onError: usersdeleteFailed.type,
    })
  );
};
export const updateSubscriptionPlan = (id, data) => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      url: "v1/subscription-plans/" + id + "/update",
      method: "put",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("primhex:token")}`,
      },
      data,
      onStart: subscriptionPlanUpdateStart.type,
      onSuccess: subscriptionPlanUpdated.type,
      onError: subscriptionPlanUpdateFailed.type,
    })
  );
};
export const deleteSubscriptionPlan = (id) => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      url: "v1/subscription-plans/" + id + "/delete",
      method: "delete",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("primhex:token")}`,
      },
      onStart: subscriptionPlanDeleteStart.type,
      onSuccess: subscriptionPlanDeleted.type,
      onError: subscriptionPlanDeleteFailed.type,
    })
  );
};
export const loadRoles = (page) => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      url: `v1/roles?page=${page}`,
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("primhex:token")}`,
      },
      onStart: rolesRequested.type,
      onSuccess: rolesReceived.type,
      onError: rolesRequestFailed.type,
    })
  );
};
export const addRole = (data) => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      url: "v1/roles/create",
      method: "post",
      data,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("primhex:token")}`,
      },
      onStart: roleAddStart.type,
      onSuccess: roleAdded.type,
      onError: roleAddFailed.type,
    })
  );
};
export const updateRole = (slug, data) => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      url: `v1/roles/${slug}/update`,
      method: "put",
      data,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("primhex:token")}`,
      },
      onStart: roleUpdateStart.type,
      onSuccess: roleUpdated.type,
      onError: roleUpdateFailed.type,
    })
  );
};
export const deleteRole = (slug) => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      url: `v1/roles/${slug}/force-delete`,
      method: "delete",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("primhex:token")}`,
      },
      onStart: roleDeleteStart.type,
      onSuccess: roleDeleted.type,
      onError: roleDeleteFailed.type,
    })
  );
};
export const loadTransactions = (page, start_date, end_date) => (
  dispatch,
  getState
) => {
  dispatch(
    apiCallBegan({
      url: `v1/subscriptions?start_date=${start_date}&end_date=${end_date}?page=${page}`,
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("primhex:token")}`,
      },
      onStart: transactionsRequested.type,
      onSuccess: transactionsReceived.type,
      onError: transactionsRequestFailed.type,
    })
  );
};
export const loadSubscriptionFeatures = () => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      url: `v1/features`,
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("primhex:token")}`,
      },
      onStart: subscriptionFeaturesRequested.type,
      onSuccess: subscriptionFeaturesReceived.type,
      onError: subscriptionFeaturesRequestFailed.type,
    })
  );
};
export const loadSubscriptions = (page) => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      url: `v1/subscription-plans?page=${page}`,
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("primhex:token")}`,
      },
      onStart: subscriptionsRequested.type,
      onSuccess: subscriptionsReceived.type,
      onError: subscriptionsRequestFailed.type,
    })
  );
};
export const addSubscription = (data) => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      url: `v1/subscription-plans/create`,
      method: "post",
      data,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("primhex:token")}`,
      },
      onStart: subscriptionAddStart.type,
      onSuccess: subscriptionAdded.type,
      onError: subscriptionAddFailed.type,
    })
  );
};


export const loadFeatures = (page) => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      url: `v1/features?page=${page}`,
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("primhex:token")}`,
      },
      onStart: subscriptionsRequested.type,
      onSuccess: subscriptionsReceived.type,
      onError: subscriptionsRequestFailed.type,
    })
  );
};
export const addFeature = (data) => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      url: `v1/features/create`,
      method: "post",
      data,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("primhex:token")}`,
      },
      onStart: subscriptionAddStart.type,
      onSuccess: subscriptionAdded.type,
      onError: subscriptionAddFailed.type,
    })
  );
};

export const updateFeature = (id, data) => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      url: "v1/features/" + id + "/update",
      method: "put",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("primhex:token")}`,
      },
      data,
      onStart: subscriptionPlanUpdateStart.type,
      onSuccess: subscriptionPlanUpdated.type,
      onError: subscriptionPlanUpdateFailed.type,
    })
  );
};
export const deleteFeature = (id) => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      url: "v1/features/" + id + "/delete",
      method: "delete",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("primhex:token")}`,
      },
      onStart: subscriptionPlanDeleteStart.type,
      onSuccess: subscriptionPlanDeleted.type,
      onError: subscriptionPlanDeleteFailed.type,
    })
  );
};


export const loadMerchants = (page) => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      url: `v1/merchants?page=${page}`,
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("primhex:token")}`,
      },
      onStart: merchantsRequested.type,
      onSuccess: merchantsReceived.type,
      onError: merchantsRequestFailed.type,
    })
  );
};
export const trackItem = (data) => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      url: `v1/tracking`,
      method: "post",
      data,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("primhex:token")}`,
      },
      onStart: trackDetailsRequested.type,
      onSuccess: trackDetailsReceived.type,
      onError: trackDetailsRequestFailed.type,
    })
  );
};
export const addMerchant = (data) => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      url: `v1/merchants`,
      method: "post",
      data,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("primhex:token")}`,
      },
      onStart: merchantRequested.type,
      onSuccess: merchantReceived.type,
      onError: merchantRequestFailed.type,
    })
  );
};
export const laodCountries = () => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      url: `countries`,
      method: "get",
      onStart: countriesRequested.type,
      onSuccess: countriesReceived.type,
      onError: countriesRequestFailed.type,
    })
  );
};
export const laodStates = (id) => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      url: `states?country_id=${id}`,
      method: "get",
      onStart: statesRequested.type,
      onSuccess: statesReceived.type,
      onError: statesRequestFailed.type,
    })
  );
};
export const changeDashboardValue = (key, value) => (dispatch, getState) => {
  dispatch({
    type: changeValue.type,
    payload: { key, value },
  });
};
