/** @format */

import React, { useEffect } from "react";
import "./style.css";
import { Row, Col, Form, Input, Select, Upload, Spin } from "antd";
import { Steps, Button, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  addMerchant,
  changeDashboardValue,
  laodCountries,
  laodStates,
} from "../../store/dashboardSlice";
import { useState } from "react";
import { useCallback } from "react";

const { Step } = Steps;

const { Option } = Select;

export default function OnboardNewBusiness() {
  const countries = useSelector((state) => state.dashboard.countries);
  const states = useSelector((state) => state.dashboard.states);
  const loadcountriesError = useSelector(
    (state) => state.dashboard.loadcountriesError
  );
  const loadcountriesStatus = useSelector(
    (state) => state.dashboard.loadcountriesStatus
  );
  const statesLoading = useSelector((state) => state.dashboard.statesLoading);
  const loadstatesError = useSelector(
    (state) => state.dashboard.loadstatesError
  );
  const loadstatesStatus = useSelector(
    (state) => state.dashboard.loadstatesStatus
  );
  const merchant = useSelector((state) => state.dashboard.merchant);
  const merchantLoading = useSelector(
    (state) => state.dashboard.merchantLoading
  );
  const loadMerchantStatus = useSelector(
    (state) => state.dashboard.loadMerchantStatus
  );
  const [id_type, setId_type] = useState("NIN");
  const [current, setCurrent] = React.useState(0);
  const dispatch = useDispatch();
  const next = useCallback(() => {
    setCurrent(current + 1);
  }, [current]);
  useEffect(() => {
    if (loadMerchantStatus === "success") {
      if (current === 0) {
        message.success("Successful, One step to go!");
      } else {
        message.success("Successful, we are done here!");
        window.location = "/company-admin";
      }
      if (merchant.merchant_code) {
        next();
      }
    }
    if (loadMerchantStatus === "failed") {
      message.error("Failed!");
    }
    if (loadstatesStatus === "failed") {
      if (loadstatesError) {
        message.error(loadstatesError);
      } else {
        message.error("Failed to load states!");
      }
    }
    if (loadcountriesStatus === "failed") {
      if (loadcountriesError) {
        message.error(loadcountriesError);
      } else {
        message.error("Failed to load countries!");
      }
    }
    dispatch(changeDashboardValue("loadMerchantStatus", "pending"));
  }, [
    loadMerchantStatus,
    dispatch,
    current,
    merchant.merchant_code,
    next,
    loadstatesError,
    loadstatesStatus,
    loadcountriesStatus,
    loadcountriesError,
  ]);
  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(laodCountries());
  }, [dispatch]);
  const StepOne = () => {
    return (
      <div id='newBusinessForm'>
        <h1>
          Business Details
          <Spin spinning={merchantLoading} />
          <Spin spinning={statesLoading} />
        </h1>
        <p>The following informations is used to collect your business info</p>
        <Form
          layout='vertical'
          form={form}
          initialValues={{}}
          size='large'
          onFinish={(values) => {
            dispatch(
              addMerchant({
                step: "1",
                ...values,
                business_logo: "https://picsum.photos/200",
              })
            );
          }}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 64 }}>
            <Col xs={24} sm={24} md={24} lg={12}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Business Name is required!",
                  },
                ]}
                label='Business Name*'
                name='business_name'>
                <Input placeholder='Andrew Dubai Ltd.' size='large' width />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Business Email Address is required!",
                    type: "email",
                  },
                ]}
                label='Business Email Address*'
                name='business_email'>
                <Input placeholder='Andrew@gmail.com' size='large' width />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Business Phone Number is required!",
                  },
                ]}
                label='Business Phone Number*'
                name='business_phone_number'>
                <Input placeholder='Communcation' size='large' />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Business Address is required!",
                  },
                ]}
                label='Business Address*'
                name='business_address'>
                <Input placeholder='Main One Street, VGC, Lekki' size='large' />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Select a country!",
                  },
                ]}
                label='Country*'
                name='business_country'>
                <Select
                  showSearch
                  placeholder='Select a country'
                  size='large'
                  optionFilterProp='children'
                  onChange={(value) => {
                    dispatch(laodStates(value));
                  }}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }>
                  {countries.map((i) => (
                    <Option value={i.id}>{i.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "State is required!",
                  },
                ]}
                label='State*'
                name='business_state'>
                <Select
                  showSearch
                  placeholder='Select a state'
                  size='large'
                  optionFilterProp='children'
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }>
                  {states.map((i) => (
                    <Option value={i.id}>{i.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "City is required!",
                  },
                ]}
                label='City*'
                name='business_city'>
                <Input placeholder='Lagos' size='large' />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "RC Number is required!",
                  },
                ]}
                label='RC Number*'
                name='business_rc_number'>
                <Input placeholder='1234567890' size='large' />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Business Logo is required!",
                  },
                ]}
                label='Upload Business Logo'
                name='business_logo'>
                <Upload
                  name='file'
                  action='https://www.mocky.io/v2/5cc8019d300000980a055e76'
                  onChange={(info) => {
                    if (info.file.status !== "uploading") {
                    }
                    if (info.file.status === "done") {
                      message.success(
                        `${info.file.name} file uploaded successfully`
                      );
                    } else if (info.file.status === "error") {
                      message.error(`${info.file.name} file upload failed.`);
                    }
                  }}>
                  <Button icon={<UploadOutlined />}>Upload Logo</Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>

          <div className='steps-action'>
            {current < steps.length - 1 && (
              <Form.Item>
                <Button
                  type='primary'
                  color='#404c96'
                  style={{ backgroundColor: "#404c96" }}
                  htmlType='submit'
                  size='large'
                  block>
                  Continue
                </Button>
              </Form.Item>
            )}
            {current === steps.length - 1 && (
              <Button
                type='primary'
                onClick={() => message.success("Processing complete!")}
                size='large'
                block>
                Submit
              </Button>
            )}
            <br />
            <br />
            {current > 0 && (
              <Button onClick={() => prev()} size='large' block>
                Previous
              </Button>
            )}
          </div>
        </Form>
      </div>
    );
  };
  const StepTwo = () => {
    return (
      <div id='newBusinessForm'>
        <h1>
          Contact Details <Spin spinning={merchantLoading} />
        </h1>
        <p>The details of the contact person</p>
        <Form
          layout='vertical'
          form={form}
          initialValues={{}}
          size='large'
          onFinish={(values) => {
            dispatch(
              addMerchant({
                ...values,
                merchant_code: merchant.uuid,
                step: "2",
                profile_image: "https://picsum.photos/200",
              })
            );
          }}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 64 }}>
            <Col xs={24} sm={24} md={24} lg={12}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "First Name is required!",
                  },
                ]}
                label='First Name*'
                name='first_name'>
                <Input placeholder='Olu' size='large' />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Last Name is required!",
                  },
                ]}
                label='Last Name*'
                name='last_name'>
                <Input placeholder='Brain' size='large' />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Phone Number is required!",
                  },
                ]}
                label='Phone Number*'
                name='phone_number'>
                <Input placeholder='07068576214' size='large' />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Email Address is required!",
                    type: "email",
                  },
                ]}
                label='Email Address*'
                name='email'>
                <Input placeholder='emmanuel@gmail.com' size='large' width />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Address is required!",
                  },
                ]}
                label='Address*'
                name='address'>
                <Input
                  placeholder='Andrew Dubai, Lagos, Nigeria'
                  size='large'
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "is required!",
                  },
                ]}
                label='Identity Type'
                name='id_type'>
                <Select
                  showSearch
                  placeholder='Identity Type'
                  size='large'
                  optionFilterProp='children'
                  onChange={(value) => {
                    setId_type(value);
                  }}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }>
                  <Option value='NIN'>NIN</Option>
                  <Option value='Drivers License'>Drivers License</Option>
                  <Option value='Int.Passport'>Int.Passport</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: `${id_type} is required!`,
                  },
                ]}
                label={`${id_type} Number`}
                name='id_number'>
                <Input placeholder='1234567890' size='large' />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Image is required!",
                  },
                ]}
                label='Upload Image'
                name='profile_image'>
                <Upload
                  name='file'
                  action='https://www.mocky.io/v2/5cc8019d300000980a055e76'
                  onChange={(info) => {
                    if (info.file.status !== "uploading") {
                    }
                    if (info.file.status === "done") {
                      message.success(
                        `${info.file.name} file uploaded successfully`
                      );
                    } else if (info.file.status === "error") {
                      message.error(`${info.file.name} file upload failed.`);
                    }
                  }}>
                  <Button icon={<UploadOutlined />}>Upload Logo</Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
          <div className='steps-action'>
            {current < steps.length - 1 && (
              <Button type='primary' onClick={() => next()} size='large' block>
                Continue
              </Button>
            )}
            {current === steps.length - 1 && (
              <Form.Item>
                <Button
                  type='primary'
                  color='#404c96'
                  style={{ backgroundColor: "#404c96" }}
                  htmlType='submit'
                  size='large'
                  block>
                  Submit
                </Button>
              </Form.Item>
            )}
            <br />
            <br />
            {current > 0 && (
              <Button onClick={() => prev()} size='large' block>
                Previous
              </Button>
            )}
          </div>
        </Form>
      </div>
    );
  };

  const steps = [
    {
      title: "First",
      content: <StepOne />,
    },
    {
      title: "Last",
      content: <StepTwo />,
    },
  ];

  const prev = () => {
    setCurrent(current - 1);
  };
  return (
    <div id='newBusiness'>
      <Steps current={current}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>

      <div className='steps-content'>{steps[current].content}</div>
    </div>
  );
}
