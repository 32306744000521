/** @format */

import React, { useEffect, useState } from "react";
import { Table, Tag } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { time_ago } from "../assets/js/nFormatter";
import { loadUsers } from "../store/dashboardSlice";

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (text) => <span>{text}</span>,
  },
  {
    title: "Age",
    dataIndex: "age",
    key: "age",
  },
  {
    title: "Email Address",
    dataIndex: "address",
    key: "address",
  },
  {
    title: "Username",
    key: "userName",
    dataIndex: "userName",
  },
  {
    title: "Roles",
    key: "role",
    dataIndex: "role",
  },
  {
    title: "Last seen",
    key: "lastSeen",
    dataIndex: "lastSeen",
  },
  {
    title: "Status",
    key: "status",
    dataIndex: "status",
    render: (tag) => {
      let color = tag === "inactive" ? "volcano" : "green";

      return (
        <Tag color={color} key={tag}>
          {tag.toUpperCase()}
        </Tag>
      );
    },
  },
];

// [
//   {
//       "id": 143,
//       "full_name": "Glory Tefe",
//       "email": "glory@gmail.com",
//       "username": "glory",
//       "role": "Admin",
//       "phone": "02009880765",
//       "status": "active",
//       "profile_image": null,
//       "created_at": "2021-11-01 10:27:47",
//       "updated_at": "2021-11-01 10:27:47"
//   },
//   {
//       "id": 142,
//       "full_name": "Emmanuel Oladejo",
//       "email": "emmanuel@gmail.com",
//       "location": null,
//       "phone": "07068576219",
//       "status": "active",
//       "profile_image": null,
//       "created_at": "2021-11-01 10:27:47",
//       "updated_at": "2021-11-01 10:27:47"
//   }
// ]
// const data = [
//   {
//     key: "1",
//     name: "John Brown",
//     age: 32,
//     address: "user@gmail.com",
//     userName: "developer",
//     role: "Admin",
//     lastSeen: "2 mins ago",
//     status: "Inactive",
//   },
//   {
//     key: "2",
//     name: "Jim Green",
//     age: 42,
//     address: "user@gmail.com",
//     userName: "loser",
//     role: "Admin",
//     lastSeen: "2 mins ago",
//     status: "Active",
//   },
//   {
//     key: "3",
//     name: "Joe Black",
//     age: 32,
//     address: "user@gmail.com",
//     userName: "loser",
//     role: "Admin",
//     lastSeen: "2 mins ago",
//     status: "Active",
//   },
// ];

export default function UserTable() {
  const dispatch = useDispatch();

  const usersPagination = useSelector(
    (state) => state.dashboard.usersPagination
  );
  const users = useSelector((state) => state.dashboard.users);
  const [current, setCurrent] = useState(1);
  useEffect(() => {
    dispatch(loadUsers(current));
  }, [dispatch, current]);
  const changePage = async (page) => {
    setCurrent(page);
    dispatch(loadUsers(page));
  };

  let userData = {};

  if(users){
    userData = users.map((i) => {
      return {
        key: i.id,
        name: i.full_name,
        age: 32,
        address: i.email,
        userName: i.full_name,
        role: "Admin",
        lastSeen: time_ago(i.updated_at),
        status: i.status,
      };
    });

  }
  return (
    <Table
      scroll={{ x: true }}
      columns={columns}
      dataSource={userData}
      bordered={false}
      showHeader={true}
      pagination={{
        current,
        defaultCurrent: usersPagination?.current_page,
        total: usersPagination?.total,
        onChange: (page, pageSize) => changePage(page),
        pageSize: Number(usersPagination?.per_page),
        showSizeChanger: false,
      }}
    />
  );
}
